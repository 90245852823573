import $ from 'jquery'

$(() => {
  // NOTE: window.opener を使って親のWindowを操作できる対策
  // https://blog.kazu69.net/2016/08/23/target-blank-vulnerability-tabnabbing-and-countermeasures/
  $('a[target=_blank]').each((_, element) => {
    const $element = $(element)
    if (!$element.attr('rel')) {
      $(element).attr('rel', 'noopener noreferrer')
    }
  })
})

import createChannel from '../../client/cable'

const createKaeruMeetingChannel = ({ kaeruMeetingId, onMessage }) => {
  createChannel({
    channelParams: [
      {
        channel: 'KaeruMeetingChannel',
        kaeruMeetingId,
      },
      {
        received: async ({ action, payload: { response } }) => {
          onMessage(response, action)
        },
      },
    ],
  })
}

export { createKaeruMeetingChannel }

import client from '@/lib/api/client'

const update = async (url, params) => {
  const { data } = await client.patch(url, {
    agenda_comment: params,
  })
  return data
}

export { update }

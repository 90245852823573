import $ from 'jquery'
import { replacePartial } from '../../lib/remote_partial'

$(document).on('click', '.js-topic-heading-block', ({ currentTarget }) => {
  const $currentTarget = $(currentTarget)
  const url = $currentTarget.data('focus-url')
  $('.js-comments-loading').removeClass('d-none')
  $('.js-topic-comments-body-block').html('')

  $.ajax({ url, type: 'GET' }).then((html) => {
    replacePartial($currentTarget.data('focus-replaces'), html)
    if ($currentTarget.data('comments-empty')) {
      const selector = $(currentTarget).data('unread-target')
      $(selector).removeClass('is-unread')
    }
  })
  $('.js-topic-heading-block').removeClass('is-current')
  $currentTarget.addClass('is-current')
})

$(document).on('ajax:success', '.js-topic-post-modal', ({ currentTarget }) => {
  $(currentTarget).modal('hide')
})

$(document).on('click', '.js-navbar-header-button', () => {
  // aside-toggledはangleテンプレートで使われているクラス名
  $('body').toggleClass('aside-toggled')
})

$(document).on('click', '.js-toggle-comment-edit', ({ currentTarget }) => {
  $(currentTarget).closest('.js-comment-block').find('.js-comment-edit-block').toggleClass('d-none')
})

$(document).on('click', '.js-toggle-topic-edit', ({ currentTarget }) => {
  $(currentTarget).closest('.js-topic-block').find('.js-topic-edit-block').toggleClass('d-none')
})

$(document).on('click', '.js-toggle-comment-reply', ({ currentTarget }) => {
  $(document)
    .find(`.js-comment-reply-block[data-id=${$(currentTarget).data('target')}]`)
    .toggleClass('d-none')
})

$(document).on('click', '.js-project-view-new-comment-btn', ({ currentTarget }) => {
  const $currentTarget = $(currentTarget)
  const url = $currentTarget.data('url')
  const formSelector = '.js-topic-status-edit-form'
  const $targetForm = $(formSelector)

  if (!$targetForm.hasClass('is-loaded')) {
    $.ajax({ url, type: 'GET' }).then((html) => {
      replacePartial(formSelector, html)
      $targetForm.addClass('is-loaded')
    })
  }

  $targetForm.collapse('toggle')
})

$(document).on('shown.bs.collapse', '.js-topic-status-edit-form', () => {
  const ele = document.getElementsByClassName('js-topic-status-edit-form-cancel-button')[0]
  if (ele) ele.scrollIntoView(false)
  $('.js-topic-status-edit-form-additional-comment').focus()
  const newCommentButton = document.getElementsByClassName('js-project-view-new-comment-btn')[0]
  newCommentButton.classList.add('d-none')
})

$(document).on('hidden.bs.collapse', '.js-topic-status-edit-form', () => {
  const newCommentButton = document.getElementsByClassName('js-project-view-new-comment-btn')[0]
  newCommentButton.classList.remove('d-none')
})

$(document).on('ajax:success', '.js-topic-status-edit-form form', () => {
  $('.js-topic-status-edit-form').collapse('hide')
})

import $ from 'jquery'
import { notifyError, notifyErrors } from './notify'

$(document).ajaxError((event, { status, responseJSON, statusText }) => {
  if (statusText === 'abort') return
  if (status < 400) return
  switch (status) {
    case 403:
      notifyError('権限がありません。')
      break
    case 404:
      notifyError('ページが見つかりませんでした。')
      break
    case 422:
      if (responseJSON.errors) {
        notifyErrors(responseJSON.errors)
      } else {
        // NOTE: InvalidAuthenticityToken や意図しない422エラー
        notifyError('リクエストエラーが発生しました。リロードしてから再度お試しください。')
      }
      break
    default:
      notifyError('リクエストエラーが発生しました。')
  }
})

import 'core-js/stable'
// import 'regenerator-runtime/runtime'
import '@/lib/jquery'
// NOTE: bootstrap4のjsを読み込む。できればパッケージ管理したいのでangleのjsは使っていない
import 'bootstrap'
import 'jquery-ujs'
import 'jquery-ujs-plus'
import select2 from 'select2'
import '@/stylesheets/windi.css'
import '@/client/cable'
import '@/lib/channels/consumer'
import '@/lib/channels/kaeru_meeting'
import '@/lib/remote_partial'
import '@/lib/autosize'
import '@/lib/clipboard'
import '@/lib/forcibly_modal'
import '@/lib/form'
import '@/lib/notify'
import '@/lib/onchange_moveto'
import '@/lib/ajax_error_handler'
import '@/lib/ajax_success_handler'
import '@/lib/loading'
import '@/lib/popover'
import '@/lib/input_file_with_preview'
import '@/lib/box'
import '@/lib/noopener'
import setupAutosave from '@/lib/autosave'
import '@/lib/check_all'
import '@/lib/tooltip'
import initVue from '@/init_vue'
import { Turbo } from '@hotwired/turbo-rails'
import '@sonicgarden/auto-submit-form-element'
import 'trix'
import '@rails/actiontext'
import '@/pages/clients/kaeru_meeting'
import '@/pages/clients/kaeru_meeting_role'
import '@/pages/clients/project'
import '@/pages/clients/project_map'
import '@/pages/clients/project_participant'
import '@/pages/clients/service_plan'
import '@/pages/clients/task_assigned_user_select'
import '@/pages/users/user_profiles'
import '@/pages/users/topic_post_modal'
import '@/pages/admin/selectable_topic_templates'
import '@/pages/flash_message'
import '@/pages/topnavbar'

select2()

Turbo.session.drive = false

document.addEventListener('DOMContentLoaded', setupAutosave)
initVue()

import $ from 'jquery'
import { replacePartial } from '../../lib/remote_partial'

$(document).on('click', '.js-user-name', (event) => {
  event.preventDefault()
  event.stopPropagation()
  const { currentTarget } = event
  const { url } = currentTarget.dataset
  $.ajax({ url, type: 'GET' }).then((html) => {
    replacePartial('.js-user-profile-modal-content', html)
    $('.js-user-profile-modal').modal()
  })
})

import $ from 'jquery'

const PARTIAL_SELECTOR = '.js-selected-topic-templates'

$(document).on('change', '.js-select-topic-template-list', () => {
  const url = $('.js-select-topic-template-list option:selected').data('url')
  if (!url) return

  $.ajax({
    url,
    type: 'GET',
  }).then((html) => {
    $(PARTIAL_SELECTOR).html(html)
  })
})

<template>
  <div>
    <div
      v-if="kaeruMeeting.kaeruMeetingAgendas.length === 0"
      class="py-3 d-flex justify-content-center border-top"
    >
      <span class="text-app-gray-800">
        議題が設定されていません
        <br />
        議題の設定は右側の【議題を追加】から入力してください
      </span>
    </div>

    <draggable
      handle=".kaeru-meeting-agenda__drag-handle"
      animation="300"
      :list="kaeruMeeting.kaeruMeetingAgendas"
      :disabled="!isDraggable"
      item-key="id"
      @end="handleChangeAgendaPosition"
    >
      <template #item="{ element: kaeruMeetingAgenda }">
        <div
          class="rounded px-2 py-3 kaeru-meeting-decisions border-top bg-white"
          :data-test-id="kaeruMeetingAgenda.id"
        >
          <div class="d-flex align-items-center mb-1">
            <Icon
              v-if="isDraggable"
              icon-name="fa-grip-vertical"
              icon-class="fas text-app-gray-500 ml-2 cursor-grab kaeru-meeting-agenda__drag-handle"
            ></Icon>
            <a
              class="ml-2 font-size-11rem font-weight-bold text-app-gray-700 cursor-pointer"
              :href="`#agenda-${kaeruMeetingAgenda.id}`"
              v-html="kaeruMeetingAgenda.agendaHtml"
            ></a>
          </div>

          <div v-for="agendaComment in decidedOpinions(kaeruMeetingAgenda)" :key="agendaComment.id">
            <div class="ml-4 mt-2 agenda-container d-flex align-items-start">
              <Icon
                icon-name="fa-check"
                icon-class="fas p-1 rounded-50rem text-white font-size-06rem decision-icon"
              ></Icon>
              <div class="d-flex flex-grow-1 flex-column ml-1">
                <div class="d-flex">
                  <div class="text-app-gray-700" v-html="agendaComment.comment"></div>
                  <KaeruMeetingDecisionTask
                    v-if="isSignedIn && agendaComment.relatedTopicsCount === 0"
                    class="ml-auto"
                    :current-user-id="currentUserId"
                    :current-user-belongs-to-wlb="currentUserBelongsToWlb"
                    :kaeru-meeting-id="kaeruMeeting.id"
                    :agenda-id="kaeruMeetingAgenda.id"
                    :agenda-comment="agendaComment"
                  ></KaeruMeetingDecisionTask>
                </div>
                <span v-if="agendaComment.relatedTopicsCount !== 0" class="pb-2">
                  <KaeruMeetingCommentRelatedTopicContainer
                    :current-user-id="currentUserId"
                    :agenda-comment="agendaComment"
                    :kaeru-meeting="kaeruMeeting"
                  />
                </span>
              </div>
            </div>
          </div>

          <div v-if="decidedOpinions(kaeruMeetingAgenda).length === 0" class="ml-4 text-muted">
            決定事項はありません
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, PropType, ref } from 'vue'
import draggable from 'vuedraggable'
import Icon from '@/components/Icon.vue'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { update } from '@/lib/api/kaeru_meetings/agenda_position'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import KaeruMeetingDecisionTask from '@/components/kaeru_meeting/KaeruMeetingDecisionTask.vue'
import KaeruMeetingCommentRelatedTopicContainer from '@/components/kaeru_meeting/KaeruMeetingCommentRelatedTopicContainer.vue'
import { KaeruMeeting } from '@/types/models'

export default defineComponent({
  components: {
    Icon,
    draggable,
    KaeruMeetingDecisionTask,
    KaeruMeetingCommentRelatedTopicContainer,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
  },
  setup(props) {
    const isDraggable = ref(true)
    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn
        ? forSignedInUser(props.kaeruMeeting.id)
        : forGuest(props.kaeruMeeting.guestUrlToken)
    }

    const decidedOpinions = computed(() => {
      return (kaeruMeetingAgenda) => {
        return kaeruMeetingAgenda.agendaComments.filter((agendaComment) => {
          return agendaComment.decidedAt !== null
        })
      }
    })

    const handleChangeAgendaPosition = (e) => {
      const agenda: any = props.kaeruMeeting.kaeruMeetingAgendas[e.newIndex]
      const params = {
        new_position: e.newIndex + 1,
      }
      const url = kaeruMeetingClient().agendaMoveUrl({
        agendaId: agenda.id,
      })
      catchAxiosError(async () => {
        await update(url, params)
      })
    }

    return {
      decidedOpinions,
      isDraggable,
      handleChangeAgendaPosition,
      isSignedIn,
    }
  },
})
</script>
<style scoped lang="scss">
.decision-icon {
  width: 22px;
  border: 1px solid;
  background-color: #00b488;
}
.agenda-container {
  line-height: 22px;
}
</style>

import client from '@/lib/api/client'
import {
  apiUserV1KaeruMeetingAgendaCommentTaskPath,
  clientProjectKaeruMeetingAgendaAgendaCommentRelatedTopicPath,
} from '@/lib/rails-routes'

const post = async (kaeruMeetingId, agendaId, commentId) => {
  const url = apiUserV1KaeruMeetingAgendaCommentTaskPath({
    kaeruMeetingId,
    agendaId,
    commentId,
  })

  await client.post(url, {})
}

const destroy = async (clientId, projectId, kaeruMeetingId, agendaId, agendaCommentId, id) => {
  const url = clientProjectKaeruMeetingAgendaAgendaCommentRelatedTopicPath({
    clientId,
    projectId,
    kaeruMeetingId,
    agendaId,
    agendaCommentId,
    id,
  })
  await client.delete(url)
}

export { post, destroy }

import $ from 'jquery'
import axios, { AxiosRequestConfig } from 'axios'
import { decamelizeKeys } from 'humps'
import { isPlainObject } from 'lodash-es'

const client = axios.create()
client.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': $.rails.csrfToken(),
  }
  // NOTE: FormDataは変換しない
  config.data = isPlainObject(config.data) ? decamelizeKeys(config.data) : config.data
  return config
})

export default client

// Don't edit manually. `rake js:routes` generates this file.
type Value = string | number
type Params<Keys extends string> = { [key in Keys]: Value } & Record<string, Value>
function process(route: string, params: Record<string, Value> | undefined, keys: string[]): string {
  if (!params) return route
  var query: string[] = [];
  for (var param in params) if (params.hasOwnProperty(param)) {
    if (keys.indexOf(param) === -1) {
      query.push(param + "=" + encodeURIComponent(params[param].toString()));
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function railsMailersPath(params?: Record<string, Value>) { return process('/rails/mailers', params, []); }
export function previewViewComponentsPath(params?: Record<string, Value>) { return process('/rails/view_components', params, []); }
export function previewViewComponentPath(params?: Record<string, Value>) { return process('/rails/view_components/*path', params, []); }
export function railsInfoPropertiesPath(params?: Record<string, Value>) { return process('/rails/info/properties', params, []); }
export function railsInfoRoutesPath(params?: Record<string, Value>) { return process('/rails/info/routes', params, []); }
export function railsInfoPath(params?: Record<string, Value>) { return process('/rails/info', params, []); }
export function newUserSessionPath(params?: Record<string, Value>) { return process('/users/sign_in', params, []); }
export function userSessionPath(params?: Record<string, Value>) { return process('/users/sign_in', params, []); }
export function destroyUserSessionPath(params?: Record<string, Value>) { return process('/users/sign_out', params, []); }
export function newUserPasswordPath(params?: Record<string, Value>) { return process('/users/password/new', params, []); }
export function editUserPasswordPath(params?: Record<string, Value>) { return process('/users/password/edit', params, []); }
export function userPasswordPath(params?: Record<string, Value>) { return process('/users/password', params, []); }
export function newUserConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation/new', params, []); }
export function userConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation', params, []); }
export function acceptUserInvitationPath(params?: Record<string, Value>) { return process('/users/invitation/accept', params, []); }
export function removeUserInvitationPath(params?: Record<string, Value>) { return process('/users/invitation/remove', params, []); }
export function newUserInvitationPath(params?: Record<string, Value>) { return process('/users/invitation/new', params, []); }
export function userInvitationPath(params?: Record<string, Value>) { return process('/users/invitation', params, []); }
export function userImagePath(params: Params<'id'>) { return process('/user_images/' + params.id + '', params, ['id']); }
export function termsOfUsePath(params?: Record<string, Value>) { return process('/terms_of_use', params, []); }
export function privacyPath(params?: Record<string, Value>) { return process('/privacy', params, []); }
export function oauth2callbackPath(params?: Record<string, Value>) { return process('/oauth2callback', params, []); }
export function authAuth0CallbackPath(params?: Record<string, Value>) { return process('/auth/auth0/callback', params, []); }
export function authFailurePath(params?: Record<string, Value>) { return process('/auth/failure', params, []); }
export function systemAdminsSignInPath(params?: Record<string, Value>) { return process('/system_admins/sign_in', params, []); }
export function systemAdminsSignOutPath(params?: Record<string, Value>) { return process('/system_admins/sign_out', params, []); }
export function thanksEntriesPath(params?: Record<string, Value>) { return process('/entries/thanks', params, []); }
export function entriesPath(params?: Record<string, Value>) { return process('/entries', params, []); }
export function newEntryPath(params?: Record<string, Value>) { return process('/entries/new', params, []); }
export function blazerPath(params?: Record<string, Value>) { return process('/blazer', params, []); }
export function goodJobPath(params?: Record<string, Value>) { return process('/good_job', params, []); }
export function editUsersProfilePath(params?: Record<string, Value>) { return process('/users/profile/edit', params, []); }
export function usersProfilePath(params?: Record<string, Value>) { return process('/users/profile', params, []); }
export function usersUserProfilePath(params: Params<'id'>) { return process('/users/user_profiles/' + params.id + '', params, ['id']); }
export function usersTopicCommentLikePath(params: Params<'topicId' | 'commentId'>) { return process('/users/topics/' + params.topicId + '/comments/' + params.commentId + '/like', params, ['topicId','commentId']); }
export function usersTopicCommentRelatedTopicsPath(params: Params<'topicId' | 'commentId'>) { return process('/users/topics/' + params.topicId + '/comments/' + params.commentId + '/related_topics', params, ['topicId','commentId']); }
export function newUsersTopicCommentRelatedTopicPath(params: Params<'topicId' | 'commentId'>) { return process('/users/topics/' + params.topicId + '/comments/' + params.commentId + '/related_topics/new', params, ['topicId','commentId']); }
export function usersTopicCommentCommentsPath(params: Params<'topicId' | 'commentId'>) { return process('/users/topics/' + params.topicId + '/comments/' + params.commentId + '/comments', params, ['topicId','commentId']); }
export function usersTopicCommentCommentPath(params: Params<'topicId' | 'commentId' | 'id'>) { return process('/users/topics/' + params.topicId + '/comments/' + params.commentId + '/comments/' + params.id + '', params, ['topicId','commentId','id']); }
export function usersTopicCommentsPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/comments', params, ['topicId']); }
export function usersTopicCommentPath(params: Params<'topicId' | 'id'>) { return process('/users/topics/' + params.topicId + '/comments/' + params.id + '', params, ['topicId','id']); }
export function usersTopicProgressPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/progress', params, ['topicId']); }
export function editUsersTopicStatusPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/status/edit', params, ['topicId']); }
export function usersTopicStatusPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/status', params, ['topicId']); }
export function usersTopicUnreadPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/unread', params, ['topicId']); }
export function usersTopicRelatedTopicsPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/related_topics', params, ['topicId']); }
export function newUsersTopicRelatedTopicPath(params: Params<'topicId'>) { return process('/users/topics/' + params.topicId + '/related_topics/new', params, ['topicId']); }
export function usersTopicPath(params: Params<'id'>) { return process('/users/topics/' + params.id + '', params, ['id']); }
export function usersNotificationSettingsPath(params?: Record<string, Value>) { return process('/users/notification_settings', params, []); }
export function usersNotificationSettingPath(params: Params<'id'>) { return process('/users/notification_settings/' + params.id + '', params, ['id']); }
export function moveHigherUsersQuestionTemplatePath(params: Params<'id'>) { return process('/users/question_templates/' + params.id + '/move_higher', params, ['id']); }
export function moveLowerUsersQuestionTemplatePath(params: Params<'id'>) { return process('/users/question_templates/' + params.id + '/move_lower', params, ['id']); }
export function usersQuestionTemplatesPath(params?: Record<string, Value>) { return process('/users/question_templates', params, []); }
export function newUsersQuestionTemplatePath(params?: Record<string, Value>) { return process('/users/question_templates/new', params, []); }
export function editUsersQuestionTemplatePath(params: Params<'id'>) { return process('/users/question_templates/' + params.id + '/edit', params, ['id']); }
export function usersQuestionTemplatePath(params: Params<'id'>) { return process('/users/question_templates/' + params.id + '', params, ['id']); }
export function editUsersPasswordChangePath(params?: Record<string, Value>) { return process('/users/password_change/edit', params, []); }
export function usersPasswordChangePath(params?: Record<string, Value>) { return process('/users/password_change', params, []); }
export function usersKaeruMeetingGuidePath(params?: Record<string, Value>) { return process('/users/kaeru_meeting_guide', params, []); }
export function usersHideKaeruMeetingGuidePath(params?: Record<string, Value>) { return process('/users/hide_kaeru_meeting_guide', params, []); }
export function clientMeetingReservationsPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/meeting_reservations', params, ['clientId']); }
export function editClientUploadFolderSettingPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/upload_folder_setting/edit', params, ['clientId']); }
export function clientUploadFolderSettingPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/upload_folder_setting', params, ['clientId']); }
export function clientScheduleFilePath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/schedule_file', params, ['clientId']); }
export function clientProjectSetupWizardStep1Path(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/setup_wizard/step1', params, ['clientId','projectId']); }
export function clientProjectDashboardPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/dashboard', params, ['clientId','projectId']); }
export function clientProjectActionPlanPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/action_plan', params, ['clientId','projectId']); }
export function moveHigherClientProjectMilestonePath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestones/' + params.id + '/move_higher', params, ['clientId','projectId','id']); }
export function moveLowerClientProjectMilestonePath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestones/' + params.id + '/move_lower', params, ['clientId','projectId','id']); }
export function clientProjectMilestonesPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestones', params, ['clientId','projectId']); }
export function newClientProjectMilestonePath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestones/new', params, ['clientId','projectId']); }
export function editClientProjectMilestonePath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestones/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function clientProjectMilestonePath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestones/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectSchedulesPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/schedules', params, ['clientId','projectId']); }
export function newClientProjectSchedulePath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/schedules/new', params, ['clientId','projectId']); }
export function editClientProjectSchedulePath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/schedules/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function clientProjectSchedulePath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/schedules/' + params.id + '', params, ['clientId','projectId','id']); }
export function copyClientProjectTagPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags/' + params.id + '/copy', params, ['clientId','projectId','id']); }
export function moveHigherClientProjectTagPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags/' + params.id + '/move_higher', params, ['clientId','projectId','id']); }
export function moveLowerClientProjectTagPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags/' + params.id + '/move_lower', params, ['clientId','projectId','id']); }
export function clientProjectTagsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags', params, ['clientId','projectId']); }
export function newClientProjectTagPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags/new', params, ['clientId','projectId']); }
export function editClientProjectTagPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function clientProjectTagPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/tags/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectParticipantsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/participants', params, ['clientId','projectId']); }
export function clientProjectParticipantPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/participants/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectTopicsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/topics', params, ['clientId','projectId']); }
export function newClientProjectTopicPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/topics/new', params, ['clientId','projectId']); }
export function clientProjectTopicPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/topics/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectMapViewTopicsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/map_view_topics', params, ['clientId','projectId']); }
export function newClientProjectMapViewTopicPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/map_view_topics/new', params, ['clientId','projectId']); }
export function clientProjectMapViewTopicPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/map_view_topics/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectMapViewMilestoneResultPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/map_view_milestone_results/' + params.id + '', params, ['clientId','projectId','id']); }
export function moveHigherClientProjectBookmarkPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/bookmarks/' + params.id + '/move_higher', params, ['clientId','projectId','id']); }
export function moveLowerClientProjectBookmarkPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/bookmarks/' + params.id + '/move_lower', params, ['clientId','projectId','id']); }
export function clientProjectBookmarksPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/bookmarks', params, ['clientId','projectId']); }
export function newClientProjectBookmarkPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/bookmarks/new', params, ['clientId','projectId']); }
export function editClientProjectBookmarkPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/bookmarks/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function clientProjectBookmarkPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/bookmarks/' + params.id + '', params, ['clientId','projectId','id']); }
export function newClientProjectPurposePath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/purpose/new', params, ['clientId','projectId']); }
export function editClientProjectPurposePath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/purpose/edit', params, ['clientId','projectId']); }
export function clientProjectPurposePath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/purpose', params, ['clientId','projectId']); }
export function clientProjectPurposeRealizationRatioPath(params: Params<'clientId' | 'projectId' | 'purposeId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/purposes/' + params.purposeId + '/realization_ratio', params, ['clientId','projectId','purposeId']); }
export function clientProjectAttachmentsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/attachments', params, ['clientId','projectId']); }
export function clientProjectPhotoAlbumDetailDraftsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/photo_album_detail_drafts', params, ['clientId','projectId']); }
export function clientProjectPhotoAlbumDetailDraftPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/photo_album_detail_drafts/' + params.id + '', params, ['clientId','projectId','id']); }
export function confirmClientProjectPhotoAlbumPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/photo_albums/' + params.id + '/confirm', params, ['clientId','projectId','id']); }
export function clientProjectPhotoAlbumsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/photo_albums', params, ['clientId','projectId']); }
export function editClientProjectPhotoAlbumPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/photo_albums/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function clientProjectPhotoAlbumPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/photo_albums/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectTeamAnalysesPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/team_analyses', params, ['clientId','projectId']); }
export function clientProjectReadAllTaskPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/read_all_task', params, ['clientId','projectId']); }
export function clientProjectKaeruMeetingStartPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/start', params, ['clientId','projectId','kaeruMeetingId']); }
export function updatePositionClientProjectKaeruMeetingAgendaPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/update_position', params, ['clientId','projectId','kaeruMeetingId','id']); }
export function clientProjectKaeruMeetingAgendaAgendaCommentRelatedTopicsPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'agendaId' | 'agendaCommentId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/agenda_comments/' + params.agendaCommentId + '/related_topics', params, ['clientId','projectId','kaeruMeetingId','agendaId','agendaCommentId']); }
export function newClientProjectKaeruMeetingAgendaAgendaCommentRelatedTopicPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'agendaId' | 'agendaCommentId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/agenda_comments/' + params.agendaCommentId + '/related_topics/new', params, ['clientId','projectId','kaeruMeetingId','agendaId','agendaCommentId']); }
export function clientProjectKaeruMeetingAgendaAgendaCommentRelatedTopicPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'agendaId' | 'agendaCommentId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/agenda_comments/' + params.agendaCommentId + '/related_topics/' + params.id + '', params, ['clientId','projectId','kaeruMeetingId','agendaId','agendaCommentId','id']); }
export function moveHigherClientProjectKaeruMeetingAgendaPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/move_higher', params, ['clientId','projectId','kaeruMeetingId','id']); }
export function moveLowerClientProjectKaeruMeetingAgendaPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/move_lower', params, ['clientId','projectId','kaeruMeetingId','id']); }
export function clientProjectKaeruMeetingAgendasPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingAgendaPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '', params, ['clientId','projectId','kaeruMeetingId','id']); }
export function clientProjectKaeruMeetingInProgressPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/in_progress', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingReviewPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/review', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingEndedPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/ended', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingGuestUrlPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/guest_url', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingRestartPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/restart', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingRestoreInReservationPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/restore_in_reservation', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingSummaryPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/summary', params, ['clientId','projectId','kaeruMeetingId']); }
export function newClientProjectKaeruMeetingCopyKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/copy_kaeru_meeting/new', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingCopyKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/copy_kaeru_meeting', params, ['clientId','projectId','kaeruMeetingId']); }
export function newClientProjectKaeruMeetingNextKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/next_kaeru_meeting/new', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingNextKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/next_kaeru_meeting', params, ['clientId','projectId','kaeruMeetingId']); }
export function editClientProjectKaeruMeetingInChargeOfKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/in_charge_of_kaeru_meeting/edit', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingInChargeOfKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/in_charge_of_kaeru_meeting', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingProceedingsContentPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/proceedings_content', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingDecisionsPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.kaeruMeetingId + '/decisions', params, ['clientId','projectId','kaeruMeetingId']); }
export function clientProjectKaeruMeetingsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings', params, ['clientId','projectId']); }
export function newClientProjectKaeruMeetingPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/new', params, ['clientId','projectId']); }
export function editClientProjectKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function clientProjectKaeruMeetingPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meetings/' + params.id + '', params, ['clientId','projectId','id']); }
export function clientProjectMilestonePackSelectionPath(params: Params<'clientId' | 'projectId' | 'milestonePackId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestone_packs/' + params.milestonePackId + '/selection', params, ['clientId','projectId','milestonePackId']); }
export function clientProjectMilestonePacksPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/milestone_packs', params, ['clientId','projectId']); }
export function clientProjectReleaseNotesPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/projects/' + params.projectId + '/release_notes', params, ['clientId','projectId']); }
export function clientProjectPath(params: Params<'clientId' | 'id'>) { return process('/clients/' + params.clientId + '/projects/' + params.id + '', params, ['clientId','id']); }
export function clientAdminsRootPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins', params, ['clientId']); }
export function deliverInvitationClientAdminsUserPath(params: Params<'clientId' | 'id'>) { return process('/clients/' + params.clientId + '/admins/users/' + params.id + '/deliver_invitation', params, ['clientId','id']); }
export function clientAdminsUserSuspendPath(params: Params<'clientId' | 'userId'>) { return process('/clients/' + params.clientId + '/admins/users/' + params.userId + '/suspend', params, ['clientId','userId']); }
export function clientAdminsUserDirectlySuspendPath(params: Params<'clientId' | 'userId'>) { return process('/clients/' + params.clientId + '/admins/users/' + params.userId + '/directly_suspend', params, ['clientId','userId']); }
export function clientAdminsUsersPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/users', params, ['clientId']); }
export function newClientAdminsUserPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/users/new', params, ['clientId']); }
export function editClientAdminsUserPath(params: Params<'clientId' | 'id'>) { return process('/clients/' + params.clientId + '/admins/users/' + params.id + '/edit', params, ['clientId','id']); }
export function clientAdminsUserPath(params: Params<'clientId' | 'id'>) { return process('/clients/' + params.clientId + '/admins/users/' + params.id + '', params, ['clientId','id']); }
export function newClientAdminsUserBulkPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/user_bulk/new', params, ['clientId']); }
export function clientAdminsUserBulkPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/user_bulk', params, ['clientId']); }
export function newClientAdminsProjectBulkPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/project_bulk/new', params, ['clientId']); }
export function clientAdminsProjectBulkPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/project_bulk', params, ['clientId']); }
export function clientAdminsProjectArchivePath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/admins/projects/' + params.projectId + '/archive', params, ['clientId','projectId']); }
export function clientAdminsProjectParticipantsPath(params: Params<'clientId' | 'projectId'>) { return process('/clients/' + params.clientId + '/admins/projects/' + params.projectId + '/participants', params, ['clientId','projectId']); }
export function clientAdminsProjectsPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/projects', params, ['clientId']); }
export function newClientAdminsProjectPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/projects/new', params, ['clientId']); }
export function editClientAdminsProjectPath(params: Params<'clientId' | 'id'>) { return process('/clients/' + params.clientId + '/admins/projects/' + params.id + '/edit', params, ['clientId','id']); }
export function clientAdminsProjectPath(params: Params<'clientId' | 'id'>) { return process('/clients/' + params.clientId + '/admins/projects/' + params.id + '', params, ['clientId','id']); }
export function newClientAdminsClientServicePlanPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/client/service_plan/new', params, ['clientId']); }
export function clientAdminsClientServicePlanPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/client/service_plan', params, ['clientId']); }
export function clientAdminsClientServicePlanCancelPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/client/service_plan_cancel', params, ['clientId']); }
export function editClientAdminsClientPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/client/edit', params, ['clientId']); }
export function clientAdminsClientPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/client', params, ['clientId']); }
export function newClientAdminsInquiryPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/inquiries/new', params, ['clientId']); }
export function clientAdminsLoginActivitiesPath(params: Params<'clientId'>) { return process('/clients/' + params.clientId + '/admins/login_activities', params, ['clientId']); }
export function clientsPath(params?: Record<string, Value>) { return process('/clients', params, []); }
export function guestsKaeruMeetingPath(params: Params<'id'>) { return process('/guests/kaeru_meetings/' + params.id + '', params, ['id']); }
export function allianceRootPath(params?: Record<string, Value>) { return process('/alliance', params, []); }
export function allianceDashboardPath(params?: Record<string, Value>) { return process('/alliance/dashboard', params, []); }
export function adminRootPath(params?: Record<string, Value>) { return process('/admin', params, []); }
export function adminDashboardPath(params?: Record<string, Value>) { return process('/admin/dashboard', params, []); }
export function adminReleaseNotesPath(params?: Record<string, Value>) { return process('/admin/release_notes', params, []); }
export function newAdminReleaseNotePath(params?: Record<string, Value>) { return process('/admin/release_notes/new', params, []); }
export function editAdminReleaseNotePath(params: Params<'id'>) { return process('/admin/release_notes/' + params.id + '/edit', params, ['id']); }
export function adminReleaseNotePath(params: Params<'id'>) { return process('/admin/release_notes/' + params.id + '', params, ['id']); }
export function adminUserIconsPath(params?: Record<string, Value>) { return process('/admin/user_icons', params, []); }
export function newAdminUserIconPath(params?: Record<string, Value>) { return process('/admin/user_icons/new', params, []); }
export function editAdminUserIconPath(params: Params<'id'>) { return process('/admin/user_icons/' + params.id + '/edit', params, ['id']); }
export function adminUserIconPath(params: Params<'id'>) { return process('/admin/user_icons/' + params.id + '', params, ['id']); }
export function adminMilestonePackMilestonePackDetailTopicPackPath(params: Params<'milestonePackId' | 'milestonePackDetailId' | 'id'>) { return process('/admin/milestone_packs/' + params.milestonePackId + '/milestone_pack_details/' + params.milestonePackDetailId + '/topic_packs/' + params.id + '', params, ['milestonePackId','milestonePackDetailId','id']); }
export function adminMilestonePackMilestonePackDetailPath(params: Params<'milestonePackId' | 'id'>) { return process('/admin/milestone_packs/' + params.milestonePackId + '/milestone_pack_details/' + params.id + '', params, ['milestonePackId','id']); }
export function adminMilestonePacksPath(params?: Record<string, Value>) { return process('/admin/milestone_packs', params, []); }
export function newAdminMilestonePackPath(params?: Record<string, Value>) { return process('/admin/milestone_packs/new', params, []); }
export function editAdminMilestonePackPath(params: Params<'id'>) { return process('/admin/milestone_packs/' + params.id + '/edit', params, ['id']); }
export function adminMilestonePackPath(params: Params<'id'>) { return process('/admin/milestone_packs/' + params.id + '', params, ['id']); }
export function adminKaeruMeetingTemplateAgendaPath(params: Params<'kaeruMeetingTemplateId' | 'id'>) { return process('/admin/kaeru_meeting_templates/' + params.kaeruMeetingTemplateId + '/agendas/' + params.id + '', params, ['kaeruMeetingTemplateId','id']); }
export function adminKaeruMeetingTemplatesPath(params?: Record<string, Value>) { return process('/admin/kaeru_meeting_templates', params, []); }
export function newAdminKaeruMeetingTemplatePath(params?: Record<string, Value>) { return process('/admin/kaeru_meeting_templates/new', params, []); }
export function editAdminKaeruMeetingTemplatePath(params: Params<'id'>) { return process('/admin/kaeru_meeting_templates/' + params.id + '/edit', params, ['id']); }
export function adminKaeruMeetingTemplatePath(params: Params<'id'>) { return process('/admin/kaeru_meeting_templates/' + params.id + '', params, ['id']); }
export function signInAsAdminUserPath(params: Params<'id'>) { return process('/admin/users/' + params.id + '/sign_in_as', params, ['id']); }
export function adminUsersPath(params?: Record<string, Value>) { return process('/admin/users', params, []); }
export function editAdminUserPath(params: Params<'id'>) { return process('/admin/users/' + params.id + '/edit', params, ['id']); }
export function adminUserPath(params: Params<'id'>) { return process('/admin/users/' + params.id + '', params, ['id']); }
export function adminAllClientUsersPath(params?: Record<string, Value>) { return process('/admin/all_client_users', params, []); }
export function editAdminClientProjectClientUserParticipantPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/client_user_participant/edit', params, ['clientId','projectId']); }
export function adminClientProjectClientUserParticipantPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/client_user_participant', params, ['clientId','projectId']); }
export function adminClientProjectProjectUsersPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/project_users', params, ['clientId','projectId']); }
export function editAdminClientProjectProjectUserPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/project_users/' + params.id + '/edit', params, ['clientId','projectId','id']); }
export function adminClientProjectProjectUserPath(params: Params<'clientId' | 'projectId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/project_users/' + params.id + '', params, ['clientId','projectId','id']); }
export function adminClientProjectBulkClientUserNotificationsPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/bulk_client_user_notifications', params, ['clientId','projectId']); }
export function moveHigherAdminClientProjectPositionPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/position/move_higher', params, ['clientId','projectId']); }
export function moveLowerAdminClientProjectPositionPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/position/move_lower', params, ['clientId','projectId']); }
export function adminClientProjectArchivePath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/archive', params, ['clientId','projectId']); }
export function adminClientProjectUserInvitationsPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/user_invitations', params, ['clientId','projectId']); }
export function newAdminClientProjectUserInvitationPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/user_invitations/new', params, ['clientId','projectId']); }
export function adminClientProjectMilestonePackSelectionPath(params: Params<'clientId' | 'projectId' | 'milestonePackId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/milestone_packs/' + params.milestonePackId + '/selection', params, ['clientId','projectId','milestonePackId']); }
export function adminClientProjectMilestonePacksPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/milestone_packs', params, ['clientId','projectId']); }
export function adminClientProjectKaeruMeetingTemplateSelectionPath(params: Params<'clientId' | 'projectId' | 'kaeruMeetingTemplateId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meeting_templates/' + params.kaeruMeetingTemplateId + '/selection', params, ['clientId','projectId','kaeruMeetingTemplateId']); }
export function adminClientProjectKaeruMeetingTemplatesPath(params: Params<'clientId' | 'projectId'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.projectId + '/kaeru_meeting_templates', params, ['clientId','projectId']); }
export function moveHigherAdminClientProjectPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.id + '/move_higher', params, ['clientId','id']); }
export function moveLowerAdminClientProjectPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.id + '/move_lower', params, ['clientId','id']); }
export function adminClientProjectsPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/projects', params, ['clientId']); }
export function newAdminClientProjectPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/projects/new', params, ['clientId']); }
export function editAdminClientProjectPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.id + '/edit', params, ['clientId','id']); }
export function adminClientProjectPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/projects/' + params.id + '', params, ['clientId','id']); }
export function adminClientBulkReInvitationsPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/bulk_re_invitations', params, ['clientId']); }
export function deliverInvitationAdminClientUserPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/users/' + params.id + '/deliver_invitation', params, ['clientId','id']); }
export function adminClientUsersPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/users', params, ['clientId']); }
export function editAdminClientUserPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/users/' + params.id + '/edit', params, ['clientId','id']); }
export function adminClientUserPath(params: Params<'clientId' | 'id'>) { return process('/admin/clients/' + params.clientId + '/users/' + params.id + '', params, ['clientId','id']); }
export function editAdminClientConsultantParticipantPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/consultant_participant/edit', params, ['clientId']); }
export function adminClientConsultantParticipantPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/consultant_participant', params, ['clientId']); }
export function editAdminClientAllianceParticipantPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/alliance_participant/edit', params, ['clientId']); }
export function adminClientAllianceParticipantPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/alliance_participant', params, ['clientId']); }
export function editAdminClientVirtualParticipantPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/virtual_participant/edit', params, ['clientId']); }
export function adminClientVirtualParticipantPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/virtual_participant', params, ['clientId']); }
export function changePlanAdminClientClientServicePlanPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/client_service_plan/change_plan', params, ['clientId']); }
export function editAdminClientClientServicePlanPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/client_service_plan/edit', params, ['clientId']); }
export function adminClientClientServicePlanPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/client_service_plan', params, ['clientId']); }
export function adminClientSuspendPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/suspend', params, ['clientId']); }
export function adminClientServicePlanPath(params: Params<'clientId'>) { return process('/admin/clients/' + params.clientId + '/service_plan', params, ['clientId']); }
export function adminClientsPath(params?: Record<string, Value>) { return process('/admin/clients', params, []); }
export function newAdminClientPath(params?: Record<string, Value>) { return process('/admin/clients/new', params, []); }
export function editAdminClientPath(params: Params<'id'>) { return process('/admin/clients/' + params.id + '/edit', params, ['id']); }
export function adminClientPath(params: Params<'id'>) { return process('/admin/clients/' + params.id + '', params, ['id']); }
export function adminClientServicePlansPath(params?: Record<string, Value>) { return process('/admin/client_service_plans', params, []); }
export function adminAdminInvitationsPath(params?: Record<string, Value>) { return process('/admin/admin_invitations', params, []); }
export function newAdminAdminInvitationPath(params?: Record<string, Value>) { return process('/admin/admin_invitations/new', params, []); }
export function adminAllianceInvitationsPath(params?: Record<string, Value>) { return process('/admin/alliance_invitations', params, []); }
export function newAdminAllianceInvitationPath(params?: Record<string, Value>) { return process('/admin/alliance_invitations/new', params, []); }
export function apiUserV1KaeruMeetingDisplayTypePath(params?: Record<string, Value>) { return process('/api/user/v1/kaeru_meeting_display_type', params, []); }
export function startApiUserV1KaeruMeetingPath(params: Params<'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.id + '/start', params, ['id']); }
export function finishApiUserV1KaeruMeetingPath(params: Params<'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.id + '/finish', params, ['id']); }
export function apiUserV1KaeruMeetingRecordOfProceedingPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/record_of_proceeding', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingAgendaPositionPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/position', params, ['kaeruMeetingId','agendaId']); }
export function apiUserV1KaeruMeetingAgendaCommentLikesPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/likes', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiUserV1KaeruMeetingAgendaCommentLikePath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId' | 'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/likes/' + params.id + '', params, ['kaeruMeetingId','agendaId','commentId','id']); }
export function apiUserV1KaeruMeetingAgendaCommentDecisionPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/decision', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiUserV1KaeruMeetingAgendaCommentPositionPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/position', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiUserV1KaeruMeetingAgendaCommentTaskPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/task', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiUserV1KaeruMeetingAgendaCommentsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments', params, ['kaeruMeetingId','agendaId']); }
export function apiUserV1KaeruMeetingAgendaCommentPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.id + '', params, ['kaeruMeetingId','agendaId','id']); }
export function apiUserV1KaeruMeetingAgendaStartsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/starts', params, ['kaeruMeetingId','agendaId']); }
export function apiUserV1KaeruMeetingAgendaEndsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/ends', params, ['kaeruMeetingId','agendaId']); }
export function apiUserV1KaeruMeetingAgendaResetsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/resets', params, ['kaeruMeetingId','agendaId']); }
export function moveHigherApiUserV1KaeruMeetingAgendaPath(params: Params<'kaeruMeetingId' | 'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/move_higher', params, ['kaeruMeetingId','id']); }
export function moveLowerApiUserV1KaeruMeetingAgendaPath(params: Params<'kaeruMeetingId' | 'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/move_lower', params, ['kaeruMeetingId','id']); }
export function apiUserV1KaeruMeetingAgendasPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingAgendaPath(params: Params<'kaeruMeetingId' | 'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '', params, ['kaeruMeetingId','id']); }
export function apiUserV1KaeruMeetingParticipantsPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/participants', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingNextMeetingCandidatesPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/next_meeting_candidates', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingAssociateWithNextMeetingPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/associate_with_next_meeting', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingPreviousMeetingCandidatesPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/previous_meeting_candidates', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingAssociateWithPreviousMeetingPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/associate_with_previous_meeting', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingImportAgendaPath(params: Params<'kaeruMeetingId'>) { return process('/api/user/v1/kaeru_meetings/' + params.kaeruMeetingId + '/import_agenda', params, ['kaeruMeetingId']); }
export function apiUserV1KaeruMeetingPath(params: Params<'id'>) { return process('/api/user/v1/kaeru_meetings/' + params.id + '', params, ['id']); }
export function apiUserV1ProjectKaeruMeetingNotificationPath(params: Params<'projectId'>) { return process('/api/user/v1/projects/' + params.projectId + '/kaeru_meeting_notification', params, ['projectId']); }
export function apiGuestV1KaeruMeetingAgendaPositionPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/position', params, ['kaeruMeetingId','agendaId']); }
export function apiGuestV1KaeruMeetingAgendaCommentPositionPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/position', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiGuestV1KaeruMeetingAgendaCommentLikesPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/likes', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiGuestV1KaeruMeetingAgendaCommentLikePath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId' | 'id'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/likes/' + params.id + '', params, ['kaeruMeetingId','agendaId','commentId','id']); }
export function apiGuestV1KaeruMeetingAgendaCommentDecisionPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'commentId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.commentId + '/decision', params, ['kaeruMeetingId','agendaId','commentId']); }
export function apiGuestV1KaeruMeetingAgendaCommentsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments', params, ['kaeruMeetingId','agendaId']); }
export function apiGuestV1KaeruMeetingAgendaCommentPath(params: Params<'kaeruMeetingId' | 'agendaId' | 'id'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/comments/' + params.id + '', params, ['kaeruMeetingId','agendaId','id']); }
export function apiGuestV1KaeruMeetingAgendaStartsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/starts', params, ['kaeruMeetingId','agendaId']); }
export function apiGuestV1KaeruMeetingAgendaEndsPath(params: Params<'kaeruMeetingId' | 'agendaId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.agendaId + '/ends', params, ['kaeruMeetingId','agendaId']); }
export function moveHigherApiGuestV1KaeruMeetingAgendaPath(params: Params<'kaeruMeetingId' | 'id'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/move_higher', params, ['kaeruMeetingId','id']); }
export function moveLowerApiGuestV1KaeruMeetingAgendaPath(params: Params<'kaeruMeetingId' | 'id'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '/move_lower', params, ['kaeruMeetingId','id']); }
export function apiGuestV1KaeruMeetingAgendasPath(params: Params<'kaeruMeetingId'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas', params, ['kaeruMeetingId']); }
export function apiGuestV1KaeruMeetingAgendaPath(params: Params<'kaeruMeetingId' | 'id'>) { return process('/api/guest/v1/kaeru_meetings/' + params.kaeruMeetingId + '/agendas/' + params.id + '', params, ['kaeruMeetingId','id']); }
export function apiGuestV1KaeruMeetingPath(params: Params<'id'>) { return process('/api/guest/v1/kaeru_meetings/' + params.id + '', params, ['id']); }
export function letterOpenerWebPath(params?: Record<string, Value>) { return process('/letter_opener', params, []); }
export function rootPath(params?: Record<string, Value>) { return process('/', params, []); }
export function turboRecedeHistoricalLocationPath(params?: Record<string, Value>) { return process('/recede_historical_location', params, []); }
export function turboResumeHistoricalLocationPath(params?: Record<string, Value>) { return process('/resume_historical_location', params, []); }
export function turboRefreshHistoricalLocationPath(params?: Record<string, Value>) { return process('/refresh_historical_location', params, []); }
export function railsPostmarkInboundEmailsPath(params?: Record<string, Value>) { return process('/rails/action_mailbox/postmark/inbound_emails', params, []); }
export function railsRelayInboundEmailsPath(params?: Record<string, Value>) { return process('/rails/action_mailbox/relay/inbound_emails', params, []); }
export function railsSendgridInboundEmailsPath(params?: Record<string, Value>) { return process('/rails/action_mailbox/sendgrid/inbound_emails', params, []); }
export function railsMandrillInboundHealthCheckPath(params?: Record<string, Value>) { return process('/rails/action_mailbox/mandrill/inbound_emails', params, []); }
export function railsMandrillInboundEmailsPath(params?: Record<string, Value>) { return process('/rails/action_mailbox/mandrill/inbound_emails', params, []); }
export function railsMailgunInboundEmailsPath(params?: Record<string, Value>) { return process('/rails/action_mailbox/mailgun/inbound_emails/mime', params, []); }
export function railsConductorInboundEmailsPath(params?: Record<string, Value>) { return process('/rails/conductor/action_mailbox/inbound_emails', params, []); }
export function newRailsConductorInboundEmailPath(params?: Record<string, Value>) { return process('/rails/conductor/action_mailbox/inbound_emails/new', params, []); }
export function editRailsConductorInboundEmailPath(params: Params<'id'>) { return process('/rails/conductor/action_mailbox/inbound_emails/' + params.id + '/edit', params, ['id']); }
export function railsConductorInboundEmailPath(params: Params<'id'>) { return process('/rails/conductor/action_mailbox/inbound_emails/' + params.id + '', params, ['id']); }
export function newRailsConductorInboundEmailSourcePath(params?: Record<string, Value>) { return process('/rails/conductor/action_mailbox/inbound_emails/sources/new', params, []); }
export function railsConductorInboundEmailSourcesPath(params?: Record<string, Value>) { return process('/rails/conductor/action_mailbox/inbound_emails/sources', params, []); }
export function railsConductorInboundEmailReroutePath(params: Params<'inboundEmailId'>) { return process('/rails/conductor/action_mailbox/' + params.inboundEmailId + '/reroute', params, ['inboundEmailId']); }
export function railsConductorInboundEmailIncineratePath(params: Params<'inboundEmailId'>) { return process('/rails/conductor/action_mailbox/' + params.inboundEmailId + '/incinerate', params, ['inboundEmailId']); }
export function railsServiceBlobPath(params: Params<'signedId'>) { return process('/rails/active_storage/blobs/redirect/' + params.signedId + '/*filename', params, ['signedId']); }
export function railsServiceBlobProxyPath(params: Params<'signedId'>) { return process('/rails/active_storage/blobs/proxy/' + params.signedId + '/*filename', params, ['signedId']); }
export function railsBlobRepresentationPath(params: Params<'signedBlobId' | 'variationKey'>) { return process('/rails/active_storage/representations/redirect/' + params.signedBlobId + '/' + params.variationKey + '/*filename', params, ['signedBlobId','variationKey']); }
export function railsBlobRepresentationProxyPath(params: Params<'signedBlobId' | 'variationKey'>) { return process('/rails/active_storage/representations/proxy/' + params.signedBlobId + '/' + params.variationKey + '/*filename', params, ['signedBlobId','variationKey']); }
export function railsDiskServicePath(params: Params<'encodedKey'>) { return process('/rails/active_storage/disk/' + params.encodedKey + '/*filename', params, ['encodedKey']); }
export function updateRailsDiskServicePath(params: Params<'encodedToken'>) { return process('/rails/active_storage/disk/' + params.encodedToken + '', params, ['encodedToken']); }
export function railsDirectUploadsPath(params?: Record<string, Value>) { return process('/rails/active_storage/direct_uploads', params, []); }

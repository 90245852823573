<template>
  <div>
    <button
      class="px-2 btn btn-xs rounded-50rem btn-secondary text-default-color font-size-07rem test-import-agenda"
      @click="handleOnClick"
    >
      この議題をコピーする
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { post } from '@/lib/api/kaeru_meetings/import_agenda'
import type { KaeruMeeting, KaeruMeetingAgenda } from '@/types/models'

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
    kaeruMeetingAgenda: {
      type: Object as PropType<KaeruMeetingAgenda>,
      required: true,
    },
  },
  setup(props) {
    const handleOnClick = () => {
      catchAxiosError(async () => {
        await post(props.kaeruMeeting.id, props.kaeruMeetingAgenda.id)
      })
    }

    return {
      handleOnClick,
    }
  },
})
</script>

export function load(): number[] {
  const stored = window.sessionStorage.getItem('likeIds')
  return stored ? JSON.parse(stored) : []
}

export function add(id: number): number[] {
  const likes = load()
  likes.push(id)
  save(likes)
  return likes
}

export function remove(id: number): number[] {
  let likes = load()
  likes = likes.filter((like) => like !== id)
  save(likes)
  return likes
}

function save(ids: number[]): void {
  window.sessionStorage.setItem('likeIds', JSON.stringify(ids))
}

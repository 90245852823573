<template>
  <form
    class="js-kaeru-meeting-post-comment mx-2"
    :action="commentPostUrl"
    accept-charset="UTF-8"
    data-remote="true"
    method="post"
  >
    <div class="d-flex justify-content-between mt-3">
      <div class="form-group string required mb-0 flex-grow-1">
        <input
          id="agenda_comment_comment"
          class="form-control string required"
          required="required"
          aria-required="true"
          type="text"
          name="agenda_comment[comment]"
          :placeholder="placeholder"
        />
      </div>
    </div>
    <div class="d-flex align-items-center mt-2">
      <label class="d-flex align-items-center">
        <input
          type="radio"
          name="agenda_comment[comment_type]"
          value="opinion"
          checked="checked"
          @click="handleOnCLickCommentTypeButton('opinion')"
        />
        <span class="ml-2">意見</span>
      </label>
      <label class="d-flex align-items-center ml-3">
        <input
          type="radio"
          name="agenda_comment[comment_type]"
          value="separator"
          @click="handleOnCLickCommentTypeButton('separator')"
        />
        <span class="ml-2">分類</span>
      </label>
      <input
        type="submit"
        name="commit"
        :value="buttonLabel"
        class="btn btn-sm btn-primary width-90 test-comment-submit ml-auto"
        data-disable
      />
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import Icon from '@/components/Icon.vue'

const LABELS = {
  opinion: {
    placeholder: '上記に対する意見を出す',
    submit: '意見を出す',
  },
  separator: {
    placeholder: '分類を作成する',
    submit: '分類を作成',
  },
}

export default defineComponent({
  components: { Icon },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    kaeruMeetingAgendaId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const dirtyCommentType = ref('opinion')

    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }
    const handleOnCLickCommentTypeButton = (commentType) => {
      dirtyCommentType.value = commentType
    }

    const commentPostUrl = computed(() => {
      return kaeruMeetingClient().commentPostUrl({
        agendaId: props.kaeruMeetingAgendaId,
        insertPosition: null,
      })
    })
    const placeholder = computed(() => {
      return LABELS[dirtyCommentType.value].placeholder
    })
    const buttonLabel = computed(() => {
      return LABELS[dirtyCommentType.value].submit
    })

    return {
      dirtyCommentType,
      handleOnCLickCommentTypeButton,
      commentPostUrl,
      placeholder,
      buttonLabel,
    }
  },
})
</script>

<template>
  <div class="d-flex align-items-center">
    <div v-if="isSignedIn" class="d-flex align-items-center">
      <a
        rel="nofollow"
        :href="agendaComment.relatedTopicPath"
        target="_blank"
        class="link-unstyled"
      >
        <KaeruMeetingCommentRelatedTopic :topic-subject="agendaComment.relatedTopicSubject" />
      </a>
      <div class="test-task-destroy" @click="handleOnClick">
        <Icon
          icon-name="fa-trash-alt"
          icon-class="fal ml-1 text-app-gray-500 cursor-pointer"
        ></Icon>
      </div>
    </div>

    <KaeruMeetingCommentRelatedTopic v-else :topic-subject="agendaComment.relatedTopicSubject" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type { KaeruMeetingAgendaComment } from '@/types/models'
import KaeruMeetingCommentRelatedTopic from '@/components/kaeru_meeting/KaeruMeetingCommentRelatedTopic.vue'
import Icon from '@/components/Icon.vue'
import { KaeruMeeting } from '@/types/models'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { destroy } from '@/lib/api/kaeru_meetings/agenda_comment_task'

export default defineComponent({
  components: { Icon, KaeruMeetingCommentRelatedTopic },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    agendaComment: {
      type: Object as PropType<KaeruMeetingAgendaComment>,
      required: true,
    },
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
  },
  setup(props) {
    const isSignedIn = props.currentUserId !== null

    const handleOnClick = () => {
      const confirmMessage =
        'このタスクを削除してもよろしいですか？\nコメントや変更した内容も削除されます'
      if (!window.confirm(confirmMessage)) return

      if (isSignedIn) {
        catchAxiosError(async () => {
          await destroy(
            props.kaeruMeeting.clientId,
            props.kaeruMeeting.projectId,
            props.kaeruMeeting.id,
            props.agendaComment.agendaId,
            props.agendaComment.id,
            props.agendaComment.relatedTopicId,
          )
        })
      }
    }

    return {
      isSignedIn,
      handleOnClick,
    }
  },
})
</script>

<template>
  <div>
    <KaeruMeetingBodyContentHeading title="議題と意見"></KaeruMeetingBodyContentHeading>
    <div
      class="kaeru-meeting-in-progress__body-content kaeru-meeting-body-content is-center bg-white"
    >
      <div class="p-3">
        <KaeruMeetingAgendaList
          :current-user-id="currentUserId"
          :current-user-belongs-to-wlb="currentUserBelongsToWlb"
          :kaeru-meeting="kaeruMeeting"
          :kaeru-meeting-agendas="kaeruMeetingAgendas"
          :show-only="showOnly"
          :kaeru-meeting-id="kaeruMeeting.id"
          :kaeru-meeting-token="kaeruMeeting.guestUrlToken"
          :guest-id="guestId"
          :comment-likes="commentLikes"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import KaeruMeetingAgendaList from '@/components/kaeru_meeting/KaeruMeetingAgendaList.vue'
import KaeruMeetingBodyContentHeading from '@/components/kaeru_meeting/KaeruMeetingBodyContentHeading.vue'

export default defineComponent({
  components: {
    KaeruMeetingAgendaList,
    KaeruMeetingBodyContentHeading,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
    kaeruMeetingAgendas: {
      type: Array,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    guestId: {
      type: String,
      default: null,
    },
    commentLikes: {
      type: Object,
      default: null,
    },
  },
})
</script>

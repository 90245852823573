import { AxiosError } from 'axios'
import { notifyError, notifyErrors } from '@/lib/notify'

const showError = (message: string) => {
  notifyError(message)
}
const showErrors = (messages: string[]) => {
  notifyErrors(messages)
}

const handleAxiosError = (error: AxiosError) => {
  const {
    response: { status, data },
  } = error

  switch (status) {
    case 401:
      showError(
        'セッションがタイムアウトしました。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。',
      )
      break
    case 403:
      showError(
        '権限がありません。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。',
      )
      break
    case 404:
      showError(
        '既に削除されている可能性があります。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。',
      )
      break
    case 422:
      if (data && data.errorMessages) {
        showErrors(data.errorMessages)
      } else {
        // NOTE: InvalidAuthenticityToken や意図しない422エラー
        showError(
          'セッションがタイムアウトしました。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。',
        )
      }
      break
    case 503:
      showError(
        'ただいまシステムメンテナンス中です。時間をあけてから再度アクセスしてください。解決しない場合はサポートまでお問い合わせください。',
      )
      break
    default:
      showError(
        'リクエストエラーが発生しました。ブラウザの更新ボタンを押してページを再読込してください。解決しない場合はサポートまでお問い合わせください。',
      )
      throw error
  }
}

export default async function catchAxiosError(fn: () => Promise<void>) {
  try {
    await fn()
  } catch (error) {
    if (!error.response) {
      throw error
    }

    handleAxiosError(error)
  }
}

export { handleAxiosError }

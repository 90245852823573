<template>
  <span>
    <div class="kaeru-meeting-in-progress__record-of-proceedings-title">
      <div class="d-flex align-items-center position-relative">
        <span class="font-size-10rem text-nowrap" v-text="kaeruMeeting.meetingOnText"></span>
        <span v-if="isBrowserIE && isEditable" class="ml-auto font-size-07rem text-danger">
          IEでご利用の場合、議事録が更新できないことがあります。
          <br />
          EdgeかChromeでご利用ください。
        </span>
        <div v-if="isEditable" class="dropdown insert-menu">
          <button
            id="dropdownMenuInsert"
            class="dropdown-toggle text-app-gray-700 font-weight-bold"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            挿入
          </button>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuInsert">
            <a class="dropdown-item" href="" @click.prevent="insertAgendas()">議題リスト</a>
            <a class="dropdown-item" href="" @click.prevent="insertParticipants()">会議参加者</a>
          </div>
        </div>
      </div>
    </div>

    <div class="kaeru-meeting-in-progress__record-of-proceedings">
      <div class="h-100 position-relative">
        <span
          class="kaeru-meeting__proceedings-content"
          :class="{ 'is-hidden': !isEditable }"
          v-html="formHtml"
        ></span>
        <div
          v-if="!isEditable"
          class="border h-100 overflow-scroll p-2 trix-content"
          v-html="proceedingsContentHtml"
        ></div>
        <span v-if="updating">
          <span class="position-absolute updating-message font-size-08rem">保存しています…</span>
        </span>
        <span v-if="completed && !updating">
          <span class="position-absolute updating-message font-size-08rem">保存しました</span>
        </span>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import { ref, computed, watch, onMounted, defineComponent } from 'vue'
import { debounce } from 'lodash-es'
import { update } from '@/lib/api/kaeru_meetings/proceedings_content'
import { handleAxiosError } from '@/lib/api/catch_axios_errors'
import { getAgendas } from '@/lib/api/kaeru_meetings/agendas'
import { getParticipants } from '@/lib/api/kaeru_meetings/participants'
import { isIEMode, isIE } from '@/lib/browser'
import { usePcScreen } from '@/lib/media_query'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'

const debouncedUpdate = debounce(update, 500)

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const composing = ref(false)
    const formHtml = ref(props.kaeruMeeting.proceedingsContentEditTag)
    const proceedingsContentHtml = ref(props.kaeruMeeting.proceedingsContent)
    const updating = ref(false)
    const completed = ref(false)
    const retrieving = ref(false)

    const isBrowserIE = isIEMode() || isIE()
    const isPc = usePcScreen()
    const isEditable = computed(() => {
      if (props.currentUserId === null) return false

      return props.kaeruMeeting.minuteTakerId === props.currentUserId
    })
    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn
        ? forSignedInUser(props.kaeruMeeting.id)
        : forGuest(props.kaeruMeeting.guestUrlToken)
    }

    watch(
      () => props.kaeruMeeting.proceedingsContent,
      (newValue, _oldValue) => {
        retrieving.value = true
        setProceedingsContentValue(newValue)
        retrieving.value = false
      },
    )
    watch(
      () => props.kaeruMeeting,
      (newValue) => {
        retrieving.value = true
        setProceedingsContentValue(newValue.proceedingsContent)
        retrieving.value = false
      },
    )

    onMounted(() => {
      const element = document.querySelector('trix-editor')
      element.addEventListener('compositionstart', (event) => {
        composing.value = true
      })
      element.addEventListener('compositionend', (event) => {
        composing.value = false
      })
      element.addEventListener('trix-file-accept', (event) => {
        event.preventDefault()
      })
      retrieveValue()
      element.addEventListener('trix-change', (event) => {
        updateProceedingsContent(event)
      })
    })

    const retrieveValue = () => {
      retrieving.value = true
      kaeruMeetingClient()
        .get()
        .then((result) => {
          setProceedingsContentValue(result.proceedingsContent)
        })
        .finally(() => {
          retrieving.value = false
        })
    }
    const setProceedingsContentValue = (proceedingsContentValue) => {
      if (isEditable.value) {
        if (props.currentUserId === props.kaeruMeeting.recordOfProceedingsUpdatedUserId) return

        const { activeElement } = document

        const element = document.querySelector('trix-editor')
        // @ts-ignore
        const beforeSelectedRange = element && element.editor.getSelectedRange()
        // @ts-ignore
        element && element.editor.setSelectedRange([0, element.value.length])
        // @ts-ignore
        element && element.editor.insertHTML(proceedingsContentValue)

        if (activeElement.className === 'trix-content') {
          // NOTE: trixの議事録を更新すると、議事録内のカーソル位置が変わってしまうので、元のカーソル位置に戻している
          // @ts-ignore
          element && element.editor.setSelectedRange(beforeSelectedRange)
        } else {
          // NOTE: trixの議事録を更新すると元々フォーカスを持っていたフィールドからフォーカスが外れてしまうので、元のフォーカス状態に戻している
          // @ts-ignore
          activeElement && activeElement.blur && activeElement.blur()
          // @ts-ignore
          activeElement && activeElement.focus && activeElement.focus()
        }
      } else {
        proceedingsContentHtml.value = proceedingsContentValue
      }
    }
    const editorValue = () => {
      const element = document.querySelector<HTMLInputElement>('trix-editor')
      if (element) {
        return element.value
      }
      return ''
    }
    const updateProceedingsContent = () => {
      if (retrieving.value) return
      if (composing.value) return

      try {
        updating.value = true

        const params = { kaeru_meeting: { proceedingsContent: editorValue() } }
        debouncedUpdate(
          props.kaeruMeeting.clientId,
          props.kaeruMeeting.projectId,
          props.kaeruMeeting.id,
          params,
        )

        // NOTE: 保存しています... とか　保存しました を読みやすい感じのタイミングで表示させるためにsetTimeoutしている
        setTimeout(() => {
          updating.value = false
        }, 300)

        completed.value = true
        setTimeout(() => {
          completed.value = false
        }, 2000)
      } catch (error) {
        if (!error.response) {
          throw error
        }

        updating.value = false
        handleAxiosError(error)
      }
    }
    const insertAgendas = () => {
      getAgendas(props.kaeruMeeting.id).then((result) => {
        insertToRichText(`議題：<br>${result.join('<br>')}`)
      })
    }
    const insertParticipants = () => {
      getParticipants(props.kaeruMeeting.id).then((result) => {
        insertToRichText(`参加者：${result.join('、')}`)
      })
    }
    const insertToRichText = (html) => {
      const element = document.querySelector('trix-editor')
      if (element) {
        // @ts-ignore
        element.editor.insertHTML(html)
      }
    }

    return {
      composing,
      formHtml,
      proceedingsContentHtml,
      updating,
      completed,
      retrieving,
      isEditable,
      isPc,
      isBrowserIE,
      retrieveValue,
      setProceedingsContentValue,
      updateProceedingsContent,
      insertAgendas,
      insertParticipants,
      insertToRichText,
    }
  },
})
</script>
<style scoped lang="scss">
.insert-menu {
  position: absolute;
  top: -2px;
  right: 0;
}
.updating-message {
  bottom: -14px;
  left: 3px;
}
</style>

<!-- FIXME: floating-vue が v-close-popper ディレクティブに対応したらそっちに乗り換える -->
<template>
  <div class="dropdown">
    <button
      class="dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <slot name="button-content"></slot>
    </button>

    <div class="dropdown-menu">
      <slot name="popper"></slot>
    </div>
  </div>
</template>

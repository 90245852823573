import client from '@/lib/api/client'
import { apiUserV1KaeruMeetingPreviousMeetingCandidatesPath } from '@/lib/rails-routes'

const getPreviousMeetingCandidates = async (kaeruMeetingId) => {
  const url = apiUserV1KaeruMeetingPreviousMeetingCandidatesPath({
    kaeruMeetingId,
  })

  const { data } = await client.get(url)
  return data
}

export { getPreviousMeetingCandidates }

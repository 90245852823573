import $ from 'jquery'
import displayFlashMessage from '../lib/display_flash_message'
import { notifyError, notifySuccess } from '../lib/notify'

$(() => displayFlashMessage())

document.addEventListener('turbo:frame-render', (event) => {
  const json = event.detail.fetchResponse.header('X-Flash-Messages')
  if (!json) return

  const flashData = JSON.parse(json)

  if (flashData.notice) notifySuccess(flashData.notice)
  if (flashData.alert) notifyError(flashData.alert)
})

<template>
  <span class="d-inline-block">
    <span :class="isTimeOver ? 'text-danger' : 'text-dark'">
      <span class="d-flex align-items-center">
        <i class="fal" :class="isTimeOver ? 'fa-exclamation-triangle' : 'fa-check'"></i>
        <span class="ml-1">{{ displayElapsedTime }}</span>
      </span>
    </span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isTimeOver: {
      type: Boolean,
      default: false,
    },
    displayElapsedTime: {
      type: String,
      default: null,
    },
  },
})
</script>

<template>
  <div>
    <span v-if="commentData.relatedTopicsCount === 0">
      <a
        :data-url="relatedTopicUrl"
        data-replaces=".js-topic-post-modal-content"
        data-toggle="modal"
        data-target=".js-topic-post-modal"
        rel="nofollow"
        class="js-remote-modal-partial d-block w-100 link-unstyled test-related-task"
        href="javascript:void(0)"
      >
        タスク登録
      </a>
    </span>
    <span v-if="commentData.relatedTopicsCount !== 0">
      <i class="fas fa-check text-muted"></i>
      <span class="text-muted">タスク登録済</span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingAgendaId: {
      type: Number,
      required: true,
    },
    commentData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const relatedTopicUrl = computed(() => {
      return `/clients/${props.clientId}/projects/${props.projectId}/kaeru_meetings/${props.kaeruMeetingId}/agendas/${props.kaeruMeetingAgendaId}/agenda_comments/${props.commentData.id}/related_topics/new`
    })

    return {
      relatedTopicUrl,
    }
  },
})
</script>
<style scoped lang="scss">
.create-task-button {
  width: 81px;
}
</style>

import $ from 'jquery'
import Clipboard from 'clipboard'
import { notifySuccess, notifyError } from './notify'

$(() => {
  const clipboard = new Clipboard('.js-copy-url')

  clipboard.on('success', () => {
    notifySuccess('URLをコピーしました')
  })
})

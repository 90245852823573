<template>
  <form
    class="js-kaeru-meeting-post-comment mr-3 my-2"
    :action="commentPostUrl"
    accept-charset="UTF-8"
    data-remote="true"
    method="post"
  >
    <div class="d-flex justify-content-between">
      <div class="form-group string required mb-0 flex-grow-1 mr-2">
        <input
          id="agenda_comment_comment"
          class="form-control string required"
          required="required"
          aria-required="true"
          type="text"
          name="agenda_comment[comment]"
          placeholder="上記に対する意見を出す"
        />
      </div>
      <input
        type="submit"
        name="commit"
        value="意見を出す"
        class="btn btn-sm btn-primary width-90 test-comment-to-separator-submit ml-auto"
        data-disable
      />
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    kaeruMeetingAgendaId: {
      type: Number,
      required: true,
    },
    insertPosition: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }

    const commentPostUrl = computed(() => {
      return kaeruMeetingClient().commentPostUrl({
        agendaId: props.kaeruMeetingAgendaId,
        insertPosition: props.insertPosition,
      })
    })

    return {
      commentPostUrl,
    }
  },
})
</script>

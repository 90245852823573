import { vwrap } from '@sonicgarden/vwrap'

import KaeruMeetingContainer from '@/components/kaeru_meeting/KaeruMeetingContainer.vue'
import KaeruMeetingNotification from '@/components/project/KaeruMeetingNotification.vue'
import ReadMore from '@/components/ReadMore.vue'

export default function () {
  vwrap('kaeru-meeting-container', KaeruMeetingContainer)
  vwrap('kaeru-meeting-notification', KaeruMeetingNotification)
  vwrap('read-more', ReadMore, { hasSlot: true })
}

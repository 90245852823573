<template>
  <div class="text-muted bg-white border rounded test-next-meeting-container">
    <div class="px-3 py-2">
      <div class="d-flex justify-content-between">
        <div class="text-app-gray-700 font-size-11rem">{{ titleText }}</div>
        <div v-if="isSignedIn && isNextMeetingAssigned">
          <a
            :href="
              editClientProjectKaeruMeetingPath({
                clientId: kaeruMeeting.clientId,
                projectId: kaeruMeeting.projectId,
                id: kaeruMeeting.nextKaeruMeetingId,
              })
            "
            class="text-app-blue-500 font-weight-bold font-size-08rem"
            target="_blank"
          >
            編集
            <Icon icon-name="fa-external-link" icon-class="fas"></Icon>
          </a>
        </div>
      </div>
    </div>

    <div class="rounded px-3 py-3 border-top">
      <div v-if="!isSignedIn" class="mt-2 d-flex justify-content-center">
        <span class="text-app-gray-800">
          ゲストの方は、次回のカエル会議が表示されません
          <br />
          会議の主催者から次回の招待ＵＲＬを受け取ってください
        </span>
      </div>

      <span v-if="isNextMeetingAssigned">
        <div v-if="isSignedIn">
          <div class="d-flex align-items-center">
            <div class="text-app-gray-900 font-weight-bold font-size-12rem">
              {{ kaeruMeeting.nextKaeruMeeting.title }}
            </div>
            <div class="ml-3 text-gray-900">
              {{ kaeruMeeting.nextKaeruMeeting.meetingOnText }}
            </div>
          </div>
          <div class="mt-3">予定している議題</div>
          <div
            v-for="kaeruMeetingAgenda in kaeruMeeting.nextKaeruMeeting.kaeruMeetingAgendas"
            :key="kaeruMeetingAgenda.id"
          >
            <div
              class="font-size-11rem font-weight-bold text-app-gray-700"
              v-html="kaeruMeetingAgenda.agendaHtml"
            ></div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <form v-if="isSignedIn" class="mt-3" @submit.prevent="destroyRequest">
            <input
              type="submit"
              value="次回会議から外す"
              class="btn btn-md btn-outline-primary"
              data-confirm="次回会議から外してもよろしいですか？"
            />
          </form>
        </div>
      </span>
      <div v-else>
        <div v-if="isSignedIn">
          <div class="mt-2 d-flex justify-content-center">
            <span class="font-size-12rem font-weight-bold text-app-gray-800">
              次回の会議を設定しましょう
            </span>
          </div>
          <div class="mt-2 vstack gap-2 align-items-center">
            <div
              class="btn btn-lg btn-secondary width-280 test-select-next-meeting"
              @click="reloadNextMeetingCandidates"
            >
              <span class="text-app-gray-700">予定中のカエル会議から選択する</span>
            </div>
            <a
              :data-url="
                newClientProjectKaeruMeetingNextKaeruMeetingPath({
                  clientId: kaeruMeeting.clientId,
                  projectId: kaeruMeeting.projectId,
                  kaeruMeetingId: kaeruMeeting.id,
                })
              "
              data-replaces=".js-next-kaeru-meeting-modal-content"
              data-toggle="modal"
              data-target=".js-next-kaeru-meeting-modal"
              rel="nofollow"
              class="js-remote-modal-partial btn btn-lg btn-secondary width-280"
              href="javascript:void(0)"
            >
              <span class="text-app-gray-700">この会議からコピーして新しく作成する</span>
            </a>
          </div>
          <form
            v-if="form.next_meeting_candidates.length !== 0"
            class="mt-4 mx-2"
            @submit.prevent="postRequest"
          >
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column align-items-center flex-fill">
                <div class="text-app-gray-700 align-self-start">次回の会議を選択してください</div>
                <select
                  id
                  v-model="form.selectedNextMeetingId"
                  name="kaeru_meeting[next_kaeru_meeting_id]"
                  class="form-control w-100"
                >
                  <option v-for="candidate in form.next_meeting_candidates" :value="candidate.id">
                    {{ candidate.meeting_on }} / {{ candidate.title_for_display }}
                  </option>
                </select>

                <input
                  type="submit"
                  value="次回会議として設定する"
                  class="mt-2 btn btn-md btn-primary"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, watch } from 'vue'
import { getNextMeetingCandidates } from '@/lib/api/kaeru_meetings/next_meeting_candidates'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { notifyError } from '@/lib/notify'
import { post, destroy } from '@/lib/api/kaeru_meetings/associate_with_next_meeting'
import Icon from '@/components/Icon.vue'
import {
  newClientProjectKaeruMeetingNextKaeruMeetingPath,
  editClientProjectKaeruMeetingPath,
} from '@/lib/rails-routes'

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const form = reactive({
      next_meeting_candidates: [],
      selectedNextMeetingId: null,
    })
    const isSignedIn = computed(() => {
      return props.currentUserId !== null
    })
    const isNextMeetingAssigned = computed(() => {
      return props.kaeruMeeting.nextKaeruMeetingId !== null
    })
    const titleText = computed(() => {
      return isNextMeetingAssigned ? '次回のカエル会議' : '次回のカエル会議を設定しましょう'
    })

    const reloadNextMeetingCandidates = async () => {
      form.next_meeting_candidates = []
      form.next_meeting_candidates = await getNextMeetingCandidates(props.kaeruMeeting.id)
      if (form.next_meeting_candidates.length === 0) {
        notifyError('予定中の会議がありません')
      }
    }

    const postRequest = () => {
      if (form.selectedNextMeetingId === null) return

      const params = {
        next_kaeru_meeting_id: form.selectedNextMeetingId,
      }
      catchAxiosError(async () => {
        await post(props.kaeruMeeting.id, params)
      })
    }

    const destroyRequest = () => {
      catchAxiosError(async () => {
        await destroy(props.kaeruMeeting.id)
        form.next_meeting_candidates = []
      })
    }

    return {
      form,
      isNextMeetingAssigned,
      titleText,
      reloadNextMeetingCandidates,
      postRequest,
      destroyRequest,
      isSignedIn,
      newClientProjectKaeruMeetingNextKaeruMeetingPath,
      editClientProjectKaeruMeetingPath,
    }
  },
})
</script>

<template>
  <div :class="commentContainerClass" :data-id="commentData.id">
    <div
      class="comment d-flex flex-column justify-content-center w-100"
      :class="{ 'is-started': isStarted }"
    >
      <div class="d-flex align-items-start w-100 comment-heading">
        <Icon
          v-if="isDraggable"
          :data-id="commentData.id"
          icon-name="fa-grip-vertical"
          icon-class="fas text-app-gray-500 ml-2 cursor-grab kaeru-meeting-agenda__handle"
        ></Icon>
        <div
          v-if="!isCommentEditFormVisible"
          class="ml-2 comment-text text-gray-500 kaeru-meeting-in-progress__comment test-display-comment"
          v-html="commentData.commentHtml"
        ></div>
        <KaeruMeetingAgendaCommentEdit
          :current-user-id="currentUserId"
          :client-id="kaeruMeeting.clientId"
          :project-id="kaeruMeeting.projectId"
          :kaeru-meeting-id="kaeruMeetingId"
          :kaeru-meeting-token="kaeruMeetingToken"
          :kaeru-meeting-agenda-id="kaeruMeetingAgendaId"
          :comment-data="commentData"
          :guest-id="guestId"
          :is-form-visible="isCommentEditFormVisible"
          @onToggleFormDisplay="toggleCommentEditForm"
        />

        <div v-if="showOnly" class="ml-auto d-flex align-items-center">
          <span
            v-if="isOpinion"
            class="ml-2 js-tooltip rounded-circle"
            title="いいね"
            rel="nofollow"
          >
            <Icon
              icon-name="fa-heart"
              icon-class="fal p-1 rounded-50rem border text-default-color font-size-07rem text-nowrap"
            >
            </Icon>
            <span class="ml-1">{{ state.likedCount }}</span>
          </span>

          <span v-if="isOpinion" class="ml-3">
            <Icon
              icon-name="fa-check"
              :icon-class="`${isDecided ? 'is-decided' : ''} p-1 fas font-size-07rem decision-icon`"
            ></Icon>
          </span>

          <KaeruMeetingAgendaCommentMenu v-if="isSignedIn && isOpinion">
            <KaeruMeetingCommentRelatedTopicCreate
              :client-id="kaeruMeeting.clientId"
              :project-id="kaeruMeeting.projectId"
              :kaeru-meeting-id="kaeruMeetingId"
              :kaeru-meeting-agenda-id="kaeruMeetingAgendaId"
              :comment-data="commentData"
            />
          </KaeruMeetingAgendaCommentMenu>
        </div>

        <div v-if="!showOnly" class="ml-auto d-flex align-items-center">
          <template v-if="isOpinion && !isCommentEditFormVisible">
            <span v-if="state.isLiked">
              <div class="ml-2 position-relative">
                <Icon
                  v-if="state.isWaitingResponse"
                  class="like-response-waiting"
                  icon-name="fa-spinner"
                  icon-class="fal fa-spin font-size-07rem"
                ></Icon>
                <a
                  class="js-tooltip"
                  title="いいね"
                  rel="nofollow"
                  @click="cancelLike"
                >
                  <span class="hstack gap-1 like-container rounded-50rem border border-warning text-warning font-size-07rem cursor-pointer text-nowrap">
                    <Icon icon-name="fa-heart" icon-class="fas p-0"></Icon>
                    <span class="user-select-none">{{ state.likedCount }}</span>
                  </span>
                </a>
              </div>
            </span>
            <span v-else>
              <div class="ml-2 position-relative">
                <Icon
                  v-if="state.isWaitingResponse"
                  class="like-response-waiting"
                  icon-name="fa-spinner"
                  icon-class="fal fa-spin font-size-07rem"
                ></Icon>
                <a class="js-tooltip rounded-circle" title="いいね" @click="createLike">
                  <span class="hstack gap-1 like-container cursor-pointer rounded-50rem border text-default-color font-size-07rem text-nowrap">
                    <Icon icon-name="fa-heart" icon-class="fal p-0"></Icon>
                    <span v-show="state.likedCount > 0" class="user-select-none">
                      {{ state.likedCount }}
                    </span>
                  </span>
                </a>
              </div>
            </span>
            <a
              v-if="isDecided"
              class="ml-3 js-tooltip"
              title="決定事項から外す"
              rel="nofollow"
              @click="cancelDecision"
            >
              <Icon
                icon-name="fa-check"
                icon-class="fas p-1 font-size-07rem cursor-pointer decision-icon is-decided"
              ></Icon>
            </a>
            <a
              v-if="!isDecided"
              class="ml-3 js-tooltip"
              title="決定事項にする"
              rel="nofollow"
              @click="createDecision"
            >
              <Icon
                icon-name="fa-check"
                icon-class="fas p-1 font-size-07rem cursor-pointer decision-icon"
              ></Icon>
            </a>
          </template>

          <div>
            <KaeruMeetingAgendaCommentMenu>
              <!-- NOTE: テキスト以外のところをクリックした時も機能して欲しいので、btn-link ではなくd-block w-100 指定している   -->
              <a
                class="link-unstyled d-block w-100 test-edit-comment dropdown-item"
                role="button"
                aria-expanded="false"
                @click.prevent="toggleCommentEditForm()"
              >
                {{ commentTypeText }}の編集
              </a>

              <!-- NOTE: テキスト以外のところをクリックした時も機能して欲しいので、btn-link ではなくd-block w-100 指定している   -->
              <a
                class="link-unstyled d-block w-100 test-destroy dropdown-item"
                data-confirm="このコメントを削除してもよろしいですか？"
                data-remote="true"
                rel="nofollow"
                data-method="delete"
                :href="commentDestroyUrl"
              >
                {{ commentTypeText }}の削除
              </a>
              <template v-if="isSignedIn && isOpinion">
                <KaeruMeetingCommentRelatedTopicCreate
                  :client-id="kaeruMeeting.clientId"
                  :project-id="kaeruMeeting.projectId"
                  :kaeru-meeting-id="kaeruMeetingId"
                  :kaeru-meeting-agenda-id="kaeruMeetingAgendaId"
                  :comment-data="commentData"
                  class="dropdown-item"
                />
              </template>
            </KaeruMeetingAgendaCommentMenu>
          </div>
        </div>
      </div>
      <div v-if="!showOnly && !isOpinion" class="ml-3">
        <a
          class="cursor-pointer"
          role="button"
          aria-expanded="false"
          @click.prevent="toggleCollapse()"
        >
          <span class="hstack gap-1 text-app-blue-500 font-size-08rem text-nowrap">
            <Icon icon-name="fa-plus-circle" icon-class="fas"></Icon>
            <span>意見を出す...</span>
          </span>
        </a>

        <KaeruMeetingAgendaPostToCategoryForm
          :class="{ collapse: true, show: !isCollapsed }"
          :current-user-id="currentUserId"
          :kaeru-meeting-id="kaeruMeetingId"
          :kaeru-meeting-token="kaeruMeetingToken"
          :kaeru-meeting-agenda-id="kaeruMeetingAgendaId"
          :insert-position="calculateNewPosition"
        />
      </div>

      <span v-if="commentData.relatedTopicsCount !== 0" class="pl-4 pb-2 mt-1">
        <KaeruMeetingCommentRelatedTopicContainer
          :current-user-id="currentUserId"
          :agenda-comment="commentData"
          :kaeru-meeting="kaeruMeeting"
        />
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, reactive, PropType } from 'vue'
import draggable from 'vuedraggable'
import client from '@/lib/api/client'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import KaeruMeetingCommentRelatedTopicContainer from '@/components/kaeru_meeting/KaeruMeetingCommentRelatedTopicContainer.vue'
import KaeruMeetingCommentRelatedTopicCreate from '@/components/kaeru_meeting/KaeruMeetingCommentRelatedTopicCreate.vue'
import KaeruMeetingAgendaPostToCategoryForm from '@/components/kaeru_meeting/KaeruMeetingCommentPostToCategoryForm.vue'
import KaeruMeetingAgendaCommentMenu from '@/components/kaeru_meeting/KaeruMeetingAgendaCommentMenu.vue'
import KaeruMeetingAgendaCommentEdit from '@/components/kaeru_meeting/KaeruMeetingAgendaCommentEditForm.vue'
import Icon from '@/components/Icon.vue'
import * as likes from '@/lib/comment_likes'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import { KaeruMeeting } from '@/types/models'

export default defineComponent({
  components: {
    KaeruMeetingCommentRelatedTopicContainer,
    KaeruMeetingCommentRelatedTopicCreate,
    KaeruMeetingAgendaPostToCategoryForm,
    KaeruMeetingAgendaCommentMenu,
    KaeruMeetingAgendaCommentEdit,
    Icon,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    guestId: {
      type: String,
      default: null,
    },
    kaeruMeetingAgendaId: {
      type: Number,
      required: true,
    },
    commentData: {
      type: Object,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      required: true,
    },
    kaeruMeetingAgendaComments: {
      type: Array,
      required: true,
    },
    commentLike: {
      type: Object,
      default: () => ({ likes: [] }),
    },
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
    isStarted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const likeIds = ref(likes.load())
    const isCollapsed = ref(true)
    const isCommentEditFormVisible = ref(false)

    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }
    const isMyLike = (like): boolean => {
      if (isSignedIn) {
        return like.userId === props.currentUserId
      }
      return like.guestId === props.guestId
    }
    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value
    }
    const toggleCommentEditForm = () => {
      isCommentEditFormVisible.value = !isCommentEditFormVisible.value
    }

    const state = reactive({
      isWaitingResponse: false,
      likedCount: props.commentLike.likes.length,
      isLiked: props.commentLike.likes.some((v) => isMyLike(v)),
    })

    watch(
      () => props.commentLike.likes,
      (newCommentLikes) => {
        state.isWaitingResponse = false
        state.likedCount = newCommentLikes.length
        state.isLiked = newCommentLikes.some((v) => isMyLike(v))
      },
    )

    const relatedTopicUrl = computed(() => {
      return `/clients/${props.kaeruMeeting.clientId}/projects/${props.kaeruMeeting.projectId}/kaeru_meetings/${props.kaeruMeetingId}/agendas/${props.kaeruMeetingAgendaId}/agenda_comments/${props.commentData.id}/related_topics/new`
    })
    const likedId = computed(() => {
      const myLike = props.commentLike.likes.find((v) => isMyLike(v))
      if (myLike === undefined) return null

      return myLike.id
    })
    const commentContainerClass = computed(() => {
      return [
        `comment-container--${props.commentData.commentType}`,
        'test-comment-container',
        { 'is-nested': props.commentData.isUnderSeparator },
        { 'is-started': props.isStarted },
      ]
    })
    const isOpinion = computed(() => {
      return props.commentData.commentType === 'opinion'
    })
    const commentTypeIcon = computed(() => {
      return isOpinion.value ? 'fa-comment' : 'fa-folder'
    })
    const commentTypeText = computed(() => {
      return isOpinion.value ? '意見' : '分類'
    })
    const commentDestroyUrl = computed(() => {
      return kaeruMeetingClient().commentUrl({
        agendaId: props.kaeruMeetingAgendaId,
        commentId: props.commentData.id,
      })
    })
    const commentlikeUrl = computed(() => {
      return kaeruMeetingClient().likePostUrl({
        agendaId: props.kaeruMeetingAgendaId,
        commentId: props.commentData.id,
      })
    })
    const commentCancelLikeUrl = computed(() => {
      return kaeruMeetingClient().likeUrl({
        agendaId: props.kaeruMeetingAgendaId,
        commentId: props.commentData.id,
        likeId: likedId.value,
      })
    })
    const commentDecisionUrl = computed(() => {
      return kaeruMeetingClient().decisionUrl({
        agendaId: props.kaeruMeetingAgendaId,
        commentId: props.commentData.id,
      })
    })
    const isDecided = computed(() => {
      return props.commentData.decidedAt !== null
    })
    const calculateNewPosition = computed(() => {
      const currentSeparatorIndex = props.kaeruMeetingAgendaComments.findIndex(
        // @ts-ignore
        (v) => v.id === props.commentData.id,
      )

      let targetIndex = currentSeparatorIndex
      for (let i = currentSeparatorIndex + 1; i < props.kaeruMeetingAgendaComments.length; i++) {
        // @ts-ignore
        if (props.kaeruMeetingAgendaComments[i].commentType === 'opinion') {
          targetIndex = i
        } else {
          break
        }
      }
      // @ts-ignore
      return props.kaeruMeetingAgendaComments[targetIndex].position + 1
    })

    const createLike = async (): Promise<void> => {
      if (state.isWaitingResponse) return
      state.likedCount += 1
      state.isLiked = true
      state.isWaitingResponse = true

      catchAxiosError(async () => {
        const { data } = await client.post(commentlikeUrl.value)

        likeIds.value = likes.add(data.id)
      })
    }
    const cancelLike = async (): Promise<void> => {
      if (state.isWaitingResponse) return
      state.likedCount -= 1
      state.isLiked = false
      state.isWaitingResponse = true

      const targetLikeId = likedId
      catchAxiosError(async () => {
        await client.delete(commentCancelLikeUrl.value)

        likeIds.value = likes.remove(targetLikeId.value)
      })
    }
    const createDecision = async (): Promise<void> => {
      catchAxiosError(async () => {
        await client.post(commentDecisionUrl.value)
      })
    }
    const cancelDecision = async (): Promise<void> => {
      catchAxiosError(async () => {
        await client.delete(commentDecisionUrl.value)
      })
    }

    return {
      isSignedIn,
      likeIds,
      commentDestroyUrl,
      relatedTopicUrl,
      commentContainerClass,
      isOpinion,
      commentTypeIcon,
      createLike,
      cancelLike,
      createDecision,
      cancelDecision,
      isDecided,
      calculateNewPosition,
      toggleCollapse,
      isCollapsed,
      state,
      isCommentEditFormVisible,
      toggleCommentEditForm,
      commentTypeText,
    }
  },
})
</script>

<style scoped lang="scss">
@use 'app/assets/stylesheets/application/resources.sass';

.comment-container--opinion {
  background-color: transparent;
  min-height: 32px;
  margin-top: 0.426em;
  padding-left: 8px;

  .comment {
    background-color: white;
    &.is-started {
      background-color: resources.$app-blue-50;
    }
  }

  .comment-text {
    font-size: 14px;
  }

  .kaeru-meeting-agenda__handle {
    margin-top: 4px;
  }
}

.comment-container--separator {
  margin-top: 1em;
  background-color: white;
  &.is-started {
    background-color: resources.$app-blue-50;
  }

  > .comment {
    .comment-heading {
      padding-left: 8px;
      padding-top: 4px;
      padding-bottom: 2px;
      background-color: #fafafa;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
    }

    .comment-text {
      margin-top: 2px;
      font-size: 14px;
    }

    .kaeru-meeting-agenda__handle {
      margin-top: 6px;
    }
  }
}
.decision-icon {
  width: 22px;
  background-color: #d5d5d5;
  border-radius: 50%;
  color: white;
  border: 1px solid;
  &.is-decided {
    background-color: #00b488;
  }
}
.like-response-waiting {
  position: absolute;
  top: 6px;
  left: 13px;
}
.like-container {
  padding: 2px 5px;
  height: 24px;
}
</style>

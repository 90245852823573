<template>
  <div
    v-if="!isHidden"
    class="kaeru-meeting-notification-container"
    :class="notificationType === 'reserved' ? 'is-reserved' : 'is-in-progress'"
  >
    <div class="d-flex justify-content-center align-items-center font-weight-bold h-100">
      <div class="d-flex align-items-center justify-content-center w-100 mt-3 mt-md-0">
        <div class="d-flex flex-column pl-2 pl-md-0">
          <div class="text-white opacity-08">
            <span v-if="notificationType === 'reserved'">まもなく会議が始まります</span>
            <span v-else>会議が始まっています</span>
          </div>
          <div class="text-white font-size-12rem" v-text="kaeruMeetingTitle"></div>
        </div>
        <div class="ml-xs-1 ml-md-3">
          <a
            class="btn btn-md kaeru-meeting-notification-entry-button text-white font-weight-bold rounded-50rem px-4 text-nowrap"
            :href="kaeruMeetingUrl"
          >
            入室
          </a>
        </div>
      </div>
      <div class="kaeru-meeting-notification-close cursor-pointer" @click="handleClickClose()">
        <div class="font-size-11rem text-white"><i class="fal fa-times"></i></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import { getKaeruMeetingNotification } from '@/lib/api/projects/kaeru_meeting_notifications'
import { dayjs } from '@/lib/dayUtil'

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const kaeruMeetingTitle = ref(null)
    const displayNotificationTimer = ref(null)
    const isHidden = ref(true)
    const closeClickedNotification = ref('')
    const kaeruMeetingUrl = ref('')
    const notificationType = ref('is-reserved')

    const displayNotification = () => {
      if (closeClickedNotification.value === kaeruMeetingUrl.value) return

      isHidden.value = false
    }
    const displayInProgressMeetingNotification = (inProgress) => {
      notificationType.value = 'inProgress'
      kaeruMeetingUrl.value = inProgress.url
      kaeruMeetingTitle.value = inProgress.title
      displayNotification()
    }
    const displayReservedMeetingNotification = (reserved) => {
      const beforeMinutes = 10
      const diff = dayjs(reserved.meetingOn)
        .add(beforeMinutes * -1, 'm')
        .diff(dayjs(), 'second')

      // NOTE: めっちゃ先の予定の場合はsetTimeoutできる上限を超えてしまうのでreturnしている
      const msec = 1000
      if (diff * msec > 2147483647) return

      notificationType.value = 'reserved'
      kaeruMeetingUrl.value = reserved.url
      kaeruMeetingTitle.value = reserved.title
      displayNotificationTimer.value = setTimeout(displayNotification, diff * msec)
    }
    const hideNotification = () => {
      closeClickedNotification.value = kaeruMeetingUrl.value
      isHidden.value = true
    }
    const handleClickClose = () => {
      hideNotification()
    }
    const displayNotificationControl = async () => {
      const result = await getKaeruMeetingNotification(props.projectId)
      if (displayNotificationTimer.value) clearInterval(displayNotificationTimer.value)

      if (result.inProgress) {
        displayInProgressMeetingNotification(result.inProgress)
      } else if (result.reserved) {
        displayReservedMeetingNotification(result.reserved)
      } else {
        hideNotification()
      }
    }

    onMounted(() => {
      displayNotificationControl()
      setInterval(displayNotificationControl, 30000)
    })

    return {
      kaeruMeetingTitle,
      isHidden,
      kaeruMeetingUrl,
      notificationType,
      handleClickClose,
    }
  },
})
</script>

<style scoped lang="scss">
@use 'app/assets/stylesheets/application/resources.sass';

.kaeru-meeting-notification-container {
  z-index: resources.$app-z-index-always-top;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 95px;
  &.is-reserved {
    background: linear-gradient(to right, rgb(5, 150, 105), rgb(6, 78, 59));
  }
  &.is-in-progress {
    background: linear-gradient(to right, rgb(167, 139, 250), rgb(236, 72, 153), rgb(239, 68, 68));
  }
  opacity: 0.8;
}
.kaeru-meeting-notification-close {
  position: absolute;
  right: 15px;
  top: 10px;
}
.kaeru-meeting-notification-entry-button {
  border: solid 2px white;
}
</style>

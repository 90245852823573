<template>
  <i :class="`${iconClass} ${iconName}`">
    <slot></slot>
  </i>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
})
</script>

<template>
  <div v-if="kaeruMeeting !== null" class="kaeru-meeting-container">
    <KaeruMeetingHeader
      :current-user-id="currentUserId"
      :client-id="clientId"
      :project-id="projectId"
      :kaeru-meeting="kaeruMeeting"
      :show-only="showOnly"
      :guest-url="guestUrl"
    />

    <div v-if="isMobile" class="mt-2">
      <ul id="action-tab" class="nav nav-tabs kaeru-meeting-in-progress-tab">
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tab-pane-decisions">議題と決定事項</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#tab-pane-agenda">議題と意見</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#tab-pane-record-of-proceedings">議事録</a>
        </li>
      </ul>
      <div class="tab-content p-0">
        <div id="tab-pane-decisions" class="tab-pane">
          <KaeruMeetingDecisionsContainer
            :current-user-id="currentUserId"
            :current-user-belongs-to-wlb="currentUserBelongsToWlb"
            :kaeru-meeting="kaeruMeeting"
            class="bg-gray-f2 kaeru-meeting-body-content w-100"
            @toggleCollapseDecisions="toggleCollapseDecisions"
          ></KaeruMeetingDecisionsContainer>
        </div>
        <div id="tab-pane-agenda" class="tab-pane active">
          <KaeruMeetingAgendaListContainer
            :current-user-id="currentUserId"
            :current-user-belongs-to-wlb="currentUserBelongsToWlb"
            :kaeru-meeting="kaeruMeeting"
            :kaeru-meeting-agendas="agendas.kaeruMeetingAgendas"
            :show-only="showOnly"
            :kaeru-meeting-id="kaeruMeeting.id"
            :kaeru-meeting-token="kaeruMeeting.guestUrlToken"
            :guest-id="guestId"
            :comment-likes="commentLikes"
            class="kaeru-meeting-body-content w-100"
          />
        </div>
        <div id="tab-pane-record-of-proceedings" class="tab-pane">
          <KaeruMeetingRecordOfProceedingsContainer
            :current-user-id="currentUserId"
            :current-user-belongs-to-wlb="currentUserBelongsToWlb"
            :show-only="showOnly || !isSignedIn"
            :kaeru-meeting="kaeruMeeting"
            class="bg-white kaeru-meeting-body-content w-100"
            @toggleCollapseRecordOfProceedings="toggleCollapseRecordOfProceedings"
          />
        </div>
      </div>
    </div>
    <div v-else class="d-block kaeru-meeting-body py-2 h-100">
      <div class="mt-2 d-flex align-items-stretch">
        <KaeruMeetingDecisionsContainer
          :current-user-id="currentUserId"
          :current-user-belongs-to-wlb="currentUserBelongsToWlb"
          :kaeru-meeting="kaeruMeeting"
          :is-collapsed="isDecisionsCollapsed"
          class="bg-gray-f2 kaeru-meeting-body-content is-left"
          :class="classOfBodyContent"
          @toggleCollapseDecisions="toggleCollapseDecisions"
        ></KaeruMeetingDecisionsContainer>

        <KaeruMeetingAgendaListContainer
          :current-user-id="currentUserId"
          :current-user-belongs-to-wlb="currentUserBelongsToWlb"
          :kaeru-meeting="kaeruMeeting"
          :kaeru-meeting-agendas="agendas.kaeruMeetingAgendas"
          :show-only="showOnly"
          :kaeru-meeting-id="kaeruMeeting.id"
          :kaeru-meeting-token="kaeruMeeting.guestUrlToken"
          :guest-id="guestId"
          :comment-likes="commentLikes"
          class="kaeru-meeting-body-content is-center bg-white"
          :class="classOfBodyContent"
        />

        <KaeruMeetingRecordOfProceedingsContainer
          :current-user-id="currentUserId"
          :current-user-belongs-to-wlb="currentUserBelongsToWlb"
          :show-only="showOnly || !isSignedIn"
          :kaeru-meeting="kaeruMeeting"
          :is-collapsed="isRecordOfProceedingsCollapsed"
          class="bg-white kaeru-meeting-body-content is-right"
          :class="classOfBodyContent"
          @toggleCollapseRecordOfProceedings="toggleCollapseRecordOfProceedings"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, reactive, watch } from 'vue'
import { useToggle } from '@vueuse/core'
import { createKaeruMeetingChannel } from '@/lib/channels/kaeru_meeting'
import KaeruMeetingAgendaListContainer from '@/components/kaeru_meeting/KaeruMeetingAgendaListContainer.vue'
import KaeruMeetingRecordOfProceedings from '@/components/kaeru_meeting/KaeruMeetingRecordOfProceedings.vue'
import { notifySuccess, notifyInfoOneMinute } from '@/lib/notify'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import { useMobileScreen } from '@/lib/media_query'
import SelectKaeruMeetingDisplayType from '@/components/kaeru_meeting/SelectKaeruMeetingDisplayType.vue'
import KaeruMeetingDecisionsContainer from '@/components/kaeru_meeting/KaeruMeetingDecisionsContainer.vue'
import KaeruMeetingDecisions from '@/components/kaeru_meeting/KaeruMeetingDecisions.vue'
import NextKaeruMeeting from '@/components/kaeru_meeting/NextKaeruMeeting.vue'
import PreviousKaeruMeeting from '@/components/kaeru_meeting/PreviousKaeruMeeting.vue'
import KaeruMeetingRecordOfProceedingsContainer from '@/components/kaeru_meeting/KaeruMeetingRecordOfProceedingsContainer.vue'
import KaeruMeetingHeader from '@/components/kaeru_meeting/KaeruMeetingHeader.vue'
import { dayjs } from '@/lib/dayUtil'

export default defineComponent({
  components: {
    KaeruMeetingRecordOfProceedingsContainer,
    KaeruMeetingAgendaListContainer,
    KaeruMeetingRecordOfProceedings,
    SelectKaeruMeetingDisplayType,
    KaeruMeetingDecisionsContainer,
    KaeruMeetingDecisions,
    NextKaeruMeeting,
    PreviousKaeruMeeting,
    KaeruMeetingHeader,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    guestUrl: {
      type: String,
      default: null,
    },
    displayType: {
      type: String,
      default: 'type_b',
    },
    autoCollapse: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleCollapseDecisions', 'toggleCollapseRecordOfProceedings'],
  setup(props) {
    const [isDecisionsCollapsed, toggleCollapseDecisions] = useToggle(props.autoCollapse)
    const [isRecordOfProceedingsCollapsed, toggleCollapseRecordOfProceedings] = useToggle(
      props.autoCollapse,
    )

    const kaeruMeeting = ref(null)
    const roleMessageDisplayed = ref(false)
    const commentMessageDisplayed = ref(false)
    const overScheduleMessageDisplayed = ref(false)
    const halfTimeMessageDisplayed = ref(false)
    const closeToTheEndMessageDisplayed = ref(false)
    const dirtyDisplayType = ref(props.displayType)
    const guestId = ref(null)
    const isProduction = () => process.env.RAILS_ENV === 'production'

    const aaa = ref(true)
    const isMobile = useMobileScreen()
    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }
    const handleOnChangeDisplayType = (newVal) => {
      dirtyDisplayType.value = newVal
    }

    const agendas = reactive({
      kaeruMeetingAgendas: null,
    })
    watch(kaeruMeeting, (newKaeruMeeting) => {
      agendas.kaeruMeetingAgendas = newKaeruMeeting.kaeruMeetingAgendas
    })
    const updateAgenda = (newAgenda, actionName) => {
      newAgenda.actionName = actionName
      const foundIndex = agendas.kaeruMeetingAgendas.findIndex(
        (agenda) => agenda.id === newAgenda.id,
      )
      if (foundIndex === -1) {
        agendas.kaeruMeetingAgendas.push(newAgenda)
      } else {
        agendas.kaeruMeetingAgendas.splice(foundIndex, 1, newAgenda)
      }
    }

    const commentLikes = reactive({
      comments: [],
    })
    watch(kaeruMeeting, (newKaeruMeeting) => {
      commentLikes.comments = generateCommentLikes(newKaeruMeeting)
    })
    const generateCommentLikes = (kaeruMeeting) => {
      const likes = kaeruMeeting.kaeruMeetingAgendas.map((kaeruMeetingAgenda) => {
        return kaeruMeetingAgenda.agendaComments
          .filter((c) => c.likes.length !== 0)
          .map((agendaComment) => {
            return {
              agendaCommentId: agendaComment.id,
              likes: agendaComment.likes,
            }
          })
      })
      return likes.flat()
    }
    const updateCommentLikes = (newCommentLike) => {
      const isHit = commentLikes.comments.some((comment) => {
        if (comment.agendaCommentId === newCommentLike.agendaCommentId) {
          comment.likes = newCommentLike.likes
          return true
        }
      })
      if (!isHit) {
        commentLikes.comments.push({
          agendaCommentId: newCommentLike.agendaCommentId,
          likes: newCommentLike.likes,
        })
      }
    }

    const onMessage = (message, action) => {
      switch (action) {
        case 'proceedings_content_update': {
          if (props.currentUserId !== message.recordOfProceedingsUpdatedUserId) {
            kaeruMeeting.value.proceedingsContent = message.proceedingsContent
          }
          break
        }
        case 'related_topic_created': {
          notifySuccess('タスクを作成しました')
          updateAgenda(message, action)
          break
        }
        case 'in_charge_of_kaeru_meeting_updated': {
          notifySuccess('係が更新されました')
          kaeruMeeting.value = message
          break
        }
        case 'agenda_comment_decided': {
          notifySuccess('決定事項にしました')
          updateAgenda(message, action)
          break
        }
        case 'started': {
          notifySuccess('会議を開始しました')
          kaeruMeeting.value = message
          break
        }
        case 'agenda_created': {
          notifySuccess('議題が追加されました')
          updateAgenda(message, action)
          break
        }
        case 'agenda_updated':
        case 'agenda_moved':
        case 'agenda_imported':
        case 'agenda_comment_cancel_decision':
        case 'task_generated':
        case 'related_topic_destroyed':
        case 'comment_moved':
        case 'comment_destroyed':
        case 'comment_updated':
        case 'comment_created': {
          updateAgenda(message, action)
          break
        }
        case 'comment_liked':
        case 'comment_like_cancelled': {
          updateCommentLikes(message)
          break
        }
        case 'next_meeting_created':
        case 'next_meeting_updated':
        case 'next_meeting_destroyed':
        case 'previous_meeting_updated':
        case 'previous_meeting_destroyed':
        case 'proceedings_update':
        case 'agenda_started':
        case 'agenda_ended':
        case 'agenda_reset':
        case 'agenda_destroyed': {
          kaeruMeeting.value = message
          break
        }
        case 'finished': {
          if (isSignedIn) {
            document.location.href = `/clients/${props.clientId}/projects/${props.projectId}/kaeru_meetings/${props.kaeruMeetingId}/review`
          } else {
            const url = new URL(window.location.href)
            url.searchParams.append('finished', '1')
            document.location.href = url.toString()
          }
          break
        }
        default:
          break
      }
    }
    const createChannel = () => {
      createKaeruMeetingChannel({
        kaeruMeetingId: props.kaeruMeetingId,
        onMessage: (message, action) => onMessage(message, action),
      })
    }

    const roleHasBeenSet = () => {
      return (
        kaeruMeeting.value.facilitatorId !== null &&
        kaeruMeeting.value.minuteTakerId !== null &&
        kaeruMeeting.value.timeKeeperId !== null &&
        kaeruMeeting.value.sortingUserId !== null
      )
    }
    const displayRoleMessage = () => {
      if (roleMessageDisplayed.value) return
      if (roleHasBeenSet()) return

      const from = dayjs(kaeruMeeting.value.startedAt)
      const to = from.clone().add(1, 'minutes')
      if (dayjs().isBetween(from, to)) {
        roleMessageDisplayed.value = true
        notifyInfoOneMinute('役割を設定しましょう')
      }
    }
    const displayCommentMessage = () => {
      if (commentMessageDisplayed.value) return

      const from = dayjs(kaeruMeeting.value.startedAt).add(5, 'minutes')
      const to = from.clone().add(1, 'minutes')
      if (dayjs().isBetween(from, to)) {
        commentMessageDisplayed.value = true
        notifyInfoOneMinute('みんなで意見が書きだせていますか？')
      }
    }
    const displayOverScheduleMessage = () => {
      if (overScheduleMessageDisplayed.value) return

      if (kaeruMeeting.value.estimatedTime < kaeruMeeting.value.agendaEstimatedTimeSum) {
        overScheduleMessageDisplayed.value = true
        notifyInfoOneMinute(
          '時間調整するか会議の延長を合意しましょう',
          '会議の予定時間を超えて議題が登録されています',
        )
      }
    }
    const displayHalfTimeMessage = () => {
      if (halfTimeMessageDisplayed.value) return

      const from = dayjs(kaeruMeeting.value.startedAt).add(
        kaeruMeeting.value.estimatedTime / 2,
        'minutes',
      )
      const to = from.clone().add(1, 'minutes')
      if (dayjs().isBetween(from, to)) {
        halfTimeMessageDisplayed.value = true
        notifyInfoOneMinute(
          '今日の会議で優先的に話したいことは話せていますか？',
          '会議時間の半分が経過しました',
        )
      }
    }
    const displayCloseToTheEndMessage = () => {
      if (closeToTheEndMessageDisplayed.value) return

      // NOTE: 会議終了予定時間の3分前に表示
      const from = dayjs(kaeruMeeting.value.startedAt).add(
        kaeruMeeting.value.estimatedTime - 3,
        'minutes',
      )
      const to = from.clone().add(1, 'minutes')
      if (dayjs().isBetween(from, to)) {
        closeToTheEndMessageDisplayed.value = true
        notifyInfoOneMinute('振り返りをして会議を終了しましょう！')
      }
    }
    const messageDisplay = () => {
      displayRoleMessage()
      displayCommentMessage()
      displayOverScheduleMessage()
      displayHalfTimeMessage()
      displayCloseToTheEndMessage()
    }

    const kaeruMeetingsUrl = computed(() => {
      return `/clients/${props.clientId}/projects/${props.projectId}/kaeru_meetings/${props.kaeruMeetingId}`
    })
    const classOfBodyContent = computed(() => {
      if (isDecisionsCollapsed.value && isRecordOfProceedingsCollapsed.value === false) {
        return 'left-collapsed'
      }

      if (isDecisionsCollapsed.value === false && isRecordOfProceedingsCollapsed.value) {
        return 'right-collapsed'
      }

      if (isDecisionsCollapsed.value && isRecordOfProceedingsCollapsed.value) {
        return 'all-collapsed'
      }

      return 'no-collapsed'
    })

    onMounted(() => {
      kaeruMeetingClient()
        .get()
        .then((result) => {
          kaeruMeeting.value = result
          guestId.value = result.guestId
          createChannel()
          if (!props.showOnly) {
            setInterval(messageDisplay, 1000)
          }
        })
    })

    return {
      kaeruMeeting,
      roleMessageDisplayed,
      commentMessageDisplayed,
      overScheduleMessageDisplayed,
      halfTimeMessageDisplayed,
      closeToTheEndMessageDisplayed,
      kaeruMeetingsUrl,
      isSignedIn,
      isMobile,
      handleOnChangeDisplayType,
      dirtyDisplayType,
      guestId,
      isProduction,
      commentLikes,
      agendas,
      toggleCollapseDecisions,
      isDecisionsCollapsed,
      toggleCollapseRecordOfProceedings,
      isRecordOfProceedingsCollapsed,
      classOfBodyContent,
      aaa,
    }
  },
})
</script>
<style scoped lang="scss">
@use 'app/assets/stylesheets/application/resources.sass';

.kaeru-meeting-body {
  background-color: resources.$app-kaeru-meeting-body-color;
}
.kaeru-meeting-body-content {
  width: 33%;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &.is-left {
    &.left-collapsed,
    &.all-collapsed {
      width: 50px;
    }

    &.right-collapsed {
      width: 50%;
    }
  }

  &.is-center {
    &.left-collapsed,
    &.right-collapsed {
      width: 50%;
    }

    &.all-collapsed {
      width: 100%;
    }
  }

  &.is-right {
    &.right-collapsed,
    &.all-collapsed {
      width: 50px;
    }

    &.left-collapsed {
      width: 50%;
    }
  }
}
.kaeru-meeting-in-progress-heading__container {
  box-shadow: 0 5px 30px -15px;
}
</style>

import $ from 'jquery'
import { notifySuccess } from '../../lib/notify'

$(document).on('change', '.js-map-view-from-year, .js-map-view-from-month', () => {
  const yearValue = document.querySelector('.js-map-view-from-year').value
  const monthValue = document.querySelector('.js-map-view-from-month').value
  const resultElement = document.querySelector('.js-map-view-to-date')

  const toDate = new Date(yearValue, monthValue - 1, 1)
  toDate.setMonth(toDate.getMonth() + 5)

  resultElement.innerHTML = `〜${toDate.getFullYear()}年${toDate.getMonth() + 1}月まで`
})

$(document).on('ajax:success', '.js-map-view-update-progress', () => {
  notifySuccess('更新しました')
})

$(() => {
  const bar = document.querySelectorAll('.js-map-today-bar')[0]
  if (bar) bar.scrollIntoView({ inline: 'center' })
  const mapItem = document.querySelectorAll('.js-map-item-container.is-after')[0]
  if (mapItem) mapItem.scrollIntoView({ block: 'center' })
})

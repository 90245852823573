<template>
  <div class="rounded">
    <span class="d-inline-block border bg-app-gray-50 px-2 rounded">
      <Icon icon-name="fa-tasks" icon-class="fal text-default-color"></Icon>
      <span class="text-default-color font-size-07rem ml-1" v-text="topicSubject"></span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  components: { Icon },
  props: {
    topicSubject: {
      type: String,
      required: true,
    },
  },
})
</script>

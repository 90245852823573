import client from '@/lib/api/client'
import { apiUserV1KaeruMeetingAssociateWithPreviousMeetingPath } from '@/lib/rails-routes'

const post = async (kaeruMeetingId, previousKaeruMeetingId) => {
  const url = apiUserV1KaeruMeetingAssociateWithPreviousMeetingPath({
    kaeruMeetingId,
    previous_kaeru_meeting_id: previousKaeruMeetingId,
  })

  const { data } = await client.post(url)
  return data
}

const destroy = async (kaeruMeetingId) => {
  const url = apiUserV1KaeruMeetingAssociateWithPreviousMeetingPath({
    kaeruMeetingId,
  })

  const { data } = await client.delete(url)
  return data
}

export { post, destroy }

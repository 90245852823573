<template>
  <div>
    <span :class="{ 'd-none': !isBeforeStart }">
      <a
        v-if="isEditable"
        data-confirm="この議題を開始しますか？"
        data-remote="true"
        rel="nofollow"
        class="btn btn-sm btn-outline-primary test-agenda-start"
        data-method="post"
        :href="agendaStartUrl"
      >
        <i class="fal fa-play-circle font-size-11rem"></i>
        <span class="font-size-09rem text-nowrap" v-text="displayEstimatedTime"></span>
      </a>
      <span v-else class="test-agenda-start d-flex align-items-center">
        <i class="fal fa-play-circle font-size-11rem"></i>
        <span class="font-size-09rem text-nowrap" v-text="displayEstimatedTime"></span>
      </span>
    </span>

    <span :class="{ 'd-none': !isStarted }">
      <a
        v-if="isEditable"
        data-confirm="この議題を終了しますか？"
        data-remote="true"
        rel="nofollow"
        class="btn btn-sm test-agenda-end"
        :class="isTimeOver ? 'btn-danger' : 'btn-primary'"
        data-method="post"
        :href="agendaEndUrl"
      >
        <i class="fas fa-spinner fa-spin"></i>
        <span v-text="displayElapsedTime"></span>
      </a>
      <span
        v-else
        class="btn btn-sm test-agenda-end"
        :class="isTimeOver ? 'btn-danger' : 'btn-primary'"
      >
        <i class="fas fa-spinner fa-spin"></i>
        <span v-text="displayElapsedTime"></span>
      </span>
    </span>

    <span :class="{ 'd-none': !isEnded }">
      <span v-if="isEditable">
        <BDropdown class="test-ended-timer-menu">
          <template #button-content>
            <KaeruMeetingAgendaEndedTimerText
              :is-time-over="isTimeOver"
              :display-elapsed-time="displayElapsedTime"
            ></KaeruMeetingAgendaEndedTimerText>
          </template>
          <template #popper>
            <a
              data-confirm="タイマーをリセットしますか？"
              data-remote="true"
              rel="nofollow"
              class="dropdown-item test-agenda-reset"
              data-method="post"
              :href="agendaResetUrl"
            >
              タイマーをリセットする
            </a>
          </template>
        </BDropdown>
      </span>
      <span v-else>
        <KaeruMeetingAgendaEndedTimerText
          :is-time-over="isTimeOver"
          :display-elapsed-time="displayElapsedTime"
        ></KaeruMeetingAgendaEndedTimerText>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue'
import { notifyInfoOneMinute } from '@/lib/notify'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import KaeruMeetingAgendaEndedTimerText from '@/components/kaeru_meeting/KaeruMeetingAgendaEndedTimerText.vue'
import BDropdown from '@/components/BDropdown.vue'
import Icon from '@/components/Icon.vue'
import { dayjs, duration } from '@/lib/dayUtil'

export default defineComponent({
  components: { KaeruMeetingAgendaEndedTimerText, BDropdown, Icon },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    agendaData: {
      type: Object,
      required: true,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const displayElapsedTime = ref('')
    const elapsedTimer = ref(null)
    const isTimeOver = ref(false)
    const overdueMessageDisplayed = ref(false)
    const alarmPlayed = ref(false)
    const alarmPlayTimer = ref(null)
    const alarmCounter = ref(0)

    onMounted(() => {
      computeElapsedTime()
      elapsedTimer.value = setInterval(computeElapsedTime, 1000)
    })
    onUnmounted(() => {
      clearInterval(elapsedTimer.value)
    })

    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }

    const displayEstimatedTime = computed(() => {
      return `${props.agendaData.estimatedTime}分`
    })
    const agendaStartUrl = computed(() => {
      return kaeruMeetingClient().agendaStartUrl({ agendaId: props.agendaData.id })
    })
    const agendaEndUrl = computed(() => {
      return kaeruMeetingClient().agendaEndUrl({ agendaId: props.agendaData.id })
    })
    const agendaResetUrl = computed(() => {
      return kaeruMeetingClient().agendaResetUrl({ agendaId: props.agendaData.id })
    })
    const isEditable = computed(() => {
      if (props.showOnly) return false
      if (props.currentUserId === null) return false
      if (props.currentUserBelongsToWlb && props.kaeruMeeting.consultantEditable) return true

      return (
        props.kaeruMeeting.facilitatorId === props.currentUserId ||
        props.kaeruMeeting.timeKeeperId === props.currentUserId
      )
    })
    const isBeforeStart = computed(() => {
      return !props.agendaData.startedAt
    })
    const isStarted = computed(() => {
      return props.agendaData.startedAt && !isEnded.value
    })
    const isEnded = computed(() => {
      return props.agendaData.endedAt !== null
    })

    const computeElapsedTime = () => {
      const format = (value) => (value < 10 ? `0${value}` : value)

      const diff = isEnded.value ? computeEndedElapsedTime() : computeStartedElapsedTime()
      const duration = dayjs.duration(diff)
      // NOTE: 開始ボタンを押した直後はNaNになる場合がある
      if (!isNaN(duration.minutes())) {
        displayElapsedTime.value = `${format(duration.hours() * 60 + duration.minutes())}:${format(
          duration.seconds(),
        )}`
      }
    }
    const computeStartedElapsedTime = () => {
      const diff = dayjs().diff(props.agendaData.startedAt)
      const duration = dayjs.duration(diff)
      isTimeOver.value =
        duration.hours() * 60 + duration.minutes() >= props.agendaData.estimatedTime

      let resultDuration = null
      if (isTimeOver.value) {
        resultDuration = dayjs()
          .add(props.agendaData.estimatedTime * -1, 'minutes')
          .diff(props.agendaData.startedAt)
        if (props.agendaData.estimatedTime !== 0) {
          displayOverdueMessage(resultDuration)
          playAlarm(resultDuration)
        }
      } else {
        resultDuration = dayjs(props.agendaData.startedAt)
          .add(props.agendaData.estimatedTime, 'minutes')
          .diff(dayjs())
      }

      return resultDuration
    }
    const computeEndedElapsedTime = () => {
      const diff = dayjs(props.agendaData.endedAt).diff(props.agendaData.startedAt)
      const duration = dayjs.duration(diff)
      isTimeOver.value =
        duration.hours() * 60 + duration.minutes() >= props.agendaData.estimatedTime

      let resultDuration = null
      if (isTimeOver.value) {
        resultDuration = dayjs(props.agendaData.endedAt)
          .add(props.agendaData.estimatedTime * -1, 'minutes')
          .diff(props.agendaData.startedAt)
      } else {
        resultDuration = dayjs(props.agendaData.startedAt)
          .add(props.agendaData.estimatedTime, 'minutes')
          .diff(props.agendaData.endedAt)
      }

      return resultDuration
    }
    const displayOverdueMessage = (diff) => {
      if (overdueMessageDisplayed.value) return

      if (dayjs.duration(diff).minutes() >= 1) {
        overdueMessageDisplayed.value = true
        notifyInfoOneMinute('時間延長しますか？', '議題の想定時間を過ぎています')
      }
    }
    const playAlarm = (diff) => {
      if (alarmPlayed.value) return

      if (dayjs.duration(diff).minutes() === 0) {
        alarmPlayed.value = true
        alarmPlayTimer.value = setInterval(() => {
          const audio = new Audio('/timer_alarm.mp3')
          audio.play()
          alarmCounter.value += 1
          if (alarmCounter.value === 3) clearInterval(alarmPlayTimer.value)
        }, 1000)
      }
    }

    return {
      isBeforeStart,
      isEditable,
      agendaStartUrl,
      displayEstimatedTime,
      isTimeOver,
      isStarted,
      agendaEndUrl,
      agendaResetUrl,
      displayElapsedTime,
      isEnded,
    }
  },
})
</script>
<style scoped lang="scss">
.ended-timer {
  :deep(.btn-link) {
    color: initial;
  }
}
</style>

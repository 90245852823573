import $ from 'jquery'

$(document).on('change', '.js-box-file-upload', ({ currentTarget }) => {
  const file = currentTarget.files[0]

  if (!file) {
    return
  }

  const filenameTarget = currentTarget.parentNode.querySelector('.js-box-file-name')
  if (currentTarget.files.length === 1) {
    filenameTarget.value = file.name
  } else {
    filenameTarget.value = ''
  }

  const filenameContainerTarget = currentTarget.parentNode.querySelector(
    '.js-box-file-name-container',
  )
  filenameContainerTarget.classList.remove('d-none')
})

$(() => {
  Array.from(document.querySelectorAll('.js-box-preview')).forEach((element) => {
    const preview = new window.Box.Preview()
    preview.show(`${element.dataset.file}`, `${element.dataset.token}`, {
      container: `.${element.dataset.target}`,
      showDownload: true,
    })
  })
})

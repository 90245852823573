import $ from 'jquery'
import toastr from 'toastr'

const notifyInfoOneMinute = (message, title = '') => {
  toastr.info(message, title, {
    timeOut: 60000,
    extendedTimeOut: 60000,
    closeButton: true,
    preventDuplicates: true,
    toastClass: 'toast is-long-display-toast',
  })
}

const notifySuccess = (message, title = '') => {
  toastr.success(message, title, { timeOut: 5000 })
}

const notifyError = (message, title = '') => {
  toastr.error(message, title, { timeOut: 20000 })
}

const notifyErrors = (errors) => {
  // htmlエスケープの為に一旦domで組み立てる
  const $ul = $('<ul></ul>')
  const $errors = errors.map((error) => $('<li></li>').text(error))
  $ul.append($errors)
  notifyError($ul.prop('outerHTML'), '')
}

export { notifySuccess, notifyError, notifyErrors, notifyInfoOneMinute }

import client from '@/lib/api/client'

const update = async (url, params) => {
  const { data } = await client.patch(url, {
    user: params,
  })
  return data
}

export { update }

import $ from 'jquery'

$(document).ajaxSuccess((event, { status, responseJSON }) => {
  if (status !== 200) return
  if (responseJSON === undefined) return

  if (responseJSON.redirect) {
    window.location = responseJSON.redirect
  }
})

<template>
  <div v-if="isMobile">
    <div class="flex-fill d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <a
          :href="
            clientProjectKaeruMeetingPath({
              clientId: kaeruMeeting.clientId,
              projectId: kaeruMeeting.projectId,
              id: kaeruMeeting.id,
            })
          "
          title="会議一覧にもどる"
          class="link-unstyled"
        >
          <div class="d-flex justify-content-center width-30 h-100">
            <i class="font-size-12rem fal fa-chevron-left"></i>
          </div>
        </a>
        <span
          class="font-weight-bold text-app-gray-900 font-size-11rem"
          v-text="kaeruMeeting.title"
        />
      </div>
      <button @click="toggleCollapse">
        <span class="btn btn-link">
          <span v-if="isOpened">閉じる</span>
          <span v-else>詳細表示</span>
        </span>
      </button>
    </div>
    <span v-if="isOpened" class="mx-2">
      <div class="mt-1 align-items-center font-size-08rem d-flex">
        <span class="text-app-gray-400">{{ kaeruMeeting.purpose }}</span>
      </div>
      <div id="popover-estimated-time-mobile" class="d-flex align-items-center mt-2">
        <div
          class="d-flex align-items-baseline kaeru-meeting-in-progress-heading__estimeted-time-container"
        >
          <span class="text-nowrap text-app-gray-500">残りの議題：</span>
          <span
            class="kaeru-meeting-in-progress-heading__estimeted-time text-app-gray-900"
            :class="{ 'text-danger': isOverSchedule }"
          >
            {{ timeRemainingOnAgenda }}
          </span>
          <span class="ml-1 text-app-gray-900">分</span>
          <span class="ml-2 text-nowrap text-app-gray-500">会議終了まで：</span>
          <span class="kaeru-meeting-in-progress-heading__estimeted-time text-app-gray-900">
            {{ timeRemainingInTheMeeting }}
          </span>
          <span class="ml-1 text-app-gray-900">分</span>
        </div>
      </div>
      <div class="mt-2">
        <span v-if="isSignedIn">
          <EditInChargeOfKaeruMeeting :kaeru-meeting="kaeruMeeting" />
        </span>
        <div class="font-size-08rem">
          <div class="flex-grow-1 kaeru-meeting-in-progress-heading__in-charge">
            <span class="text-app-gray-500">進行役：</span>
            <span class="ml-1 text-app-gray-900">{{ kaeruMeeting.facilitatorName }}</span>
            <span class="ml-2 text-app-gray-500">時間管理係：</span>
            <span class="ml-1 text-app-gray-900">{{ kaeruMeeting.timeKeeperName }}</span>
          </div>
          <div class="flex-grow-1 kaeru-meeting-in-progress-heading__in-charge">
            <span class="text-app-gray-500">並び替え係：</span>
            <span class="ml-1 text-app-gray-900">{{ sortingUserForDisplay }}</span>
            <span class="ml-2 text-app-gray-500">議事録係：</span>
            <span class="ml-1 text-app-gray-900">{{ kaeruMeeting.minuteTakerName }}</span>
          </div>
          <div class="flex-grow-1 kaeru-meeting-in-progress-heading__in-charge">
            <span class="text-app-gray-500">反応促進係：</span>
            <span class="ml-1 text-app-gray-900">
              {{ kaeruMeeting.reactionAccelerationUserName }}
            </span>
            <span class="ml-2 text-app-gray-500">決定事項確認係：</span>
            <span class="ml-1 text-app-gray-900">
              {{ kaeruMeeting.decisionConfirmationUserName }}
            </span>
          </div>
        </div>
      </div>
      <button
        v-show="guestUrl"
        type="button"
        class="mt-2 btn-link js-copy-url kaeru-meeting-in-progress-heading__guest-url"
        :data-clipboard-text="guestUrl"
      >
        <i class="fal fa-copy" />
        ゲスト用URLをコピーする
      </button>
    </span>
  </div>

  <div v-else class="d-flex align-items-stretch my-1 kaeru-meeting-in-progress-heading__container">
    <div class="d-flex justify-content-center">
      <a
        :href="
          clientProjectKaeruMeetingPath({
            clientId: kaeruMeeting.clientId,
            projectId: kaeruMeeting.projectId,
            id: kaeruMeeting.id,
          })
        "
        title="会議一覧にもどる"
        class="link-unstyled"
      >
        <div class="mt-2 d-flex justify-content-center align-items-start width-30 h-100">
          <i class="font-size-12rem fal fa-chevron-left"></i>
        </div>
      </a>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center mt-1 gap-4">
        <span
          class="font-weight-bold text-app-gray-900 font-size-11rem"
          v-text="kaeruMeeting.title"
        />
        <button
          v-show="guestUrl"
          type="button"
          class="btn-link js-copy-url kaeru-meeting-in-progress-heading__guest-url"
          :data-clipboard-text="guestUrl"
        >
          <i class="fal fa-copy" />
          ゲスト用URLをコピーする
        </button>
      </div>
      <div class="align-items-center font-size-08rem d-flex">
        <span class="text-app-gray-400">{{ kaeruMeeting.purpose }}</span>
      </div>
      <div class="align-items-center font-size-08rem d-flex">
        <span v-if="isSignedIn">
          <EditInChargeOfKaeruMeeting :kaeru-meeting="kaeruMeeting" />
        </span>
        <div class="flex-grow-1 kaeru-meeting-in-progress-heading__in-charge">
          <span class="ml-2 text-app-gray-500">進行役：</span>
          <span class="ml-1 text-app-gray-900">{{ kaeruMeeting.facilitatorName }}</span>
          <span class="ml-2 text-app-gray-500">時間管理係：</span>
          <span class="ml-1 text-app-gray-900">{{ kaeruMeeting.timeKeeperName }}</span>
          <span class="ml-2 text-app-gray-500">並び替え係：</span>
          <span class="ml-1 text-app-gray-900">{{ sortingUserForDisplay }}</span>
          <span class="ml-2 text-app-gray-500">議事録係：</span>
          <span class="ml-1 text-app-gray-900">{{ kaeruMeeting.minuteTakerName }}</span>
          <span class="ml-2 text-app-gray-500">反応促進係：</span>
          <span class="ml-1 text-app-gray-900">
            {{ kaeruMeeting.reactionAccelerationUserName }}
          </span>
          <span class="ml-2 text-app-gray-500">決定事項確認係：</span>
          <span class="ml-1 text-app-gray-900">
            {{ kaeruMeeting.decisionConfirmationUserName }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-none d-md-flex align-items-center ml-auto mt-2 mr-2">
      <div id="popover-estimated-time" class="d-flex align-items-center px-3 py-2">
        <div
          class="d-flex align-items-baseline text-app-gray-900 kaeru-meeting-in-progress-heading__estimeted-time-container"
        >
          <span class="text-nowrap">残りの議題：</span>
          <span
            class="kaeru-meeting-in-progress-heading__estimeted-time"
            :class="{ 'text-danger': isOverSchedule }"
            v-text="timeRemainingOnAgenda"
          ></span>
          <span class="ml-1">分</span>
          <span class="ml-2 text-nowrap">会議終了まで：</span>
          <span
            class="kaeru-meeting-in-progress-heading__estimeted-time"
            v-text="timeRemainingInTheMeeting"
          ></span>
          <span class="ml-1">分</span>
        </div>
      </div>

      <KaeruMeetingStartEnd
        :current-user-id="currentUserId"
        :kaeru-meeting="kaeruMeeting"
        :show-only="showOnly"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, PropType, onUnmounted } from 'vue'
import { useToggle } from '@vueuse/core'
import { useMobileScreen } from '@/lib/media_query'
import { KaeruMeeting } from '@/types/models'
import EditInChargeOfKaeruMeeting from '@/components/kaeru_meeting/EditInChargeOfKaeruMeeting.vue'
import KaeruMeetingStartEnd from '@/components/kaeru_meeting/KaeruMeetingStartEnd.vue'
import Icon from '@/components/Icon.vue'
import VDropdown from '@/components/VDropdown.vue'
import { clientProjectKaeruMeetingPath } from '@/lib/rails-routes'
import { dayjs } from '@/lib/dayUtil'

const [isOpened, toggleCollapse] = useToggle(false)

export default defineComponent({
  components: { EditInChargeOfKaeruMeeting, KaeruMeetingStartEnd, Icon, VDropdown },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    guestUrl: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const timeRemainingOnAgenda = ref(null)
    const timeRemainingInTheMeeting = ref(null)
    const isMobile = useMobileScreen()
    const isSignedIn = props.currentUserId !== null
    const timeRemainingTimer = ref(null)

    const sortingUserForDisplay = computed(() => {
      return props.kaeruMeeting.everyoneCanBeSorting ? '全員' : props.kaeruMeeting.sortingUserName
    })

    const agendaEstimatedTimeSum = computed(() => {
      return props.kaeruMeeting.kaeruMeetingAgendas.reduce((sum, agenda) => {
        return sum + agenda.estimatedTime
      }, 0)
    })
    const isOverSchedule = computed(
      () => agendaEstimatedTimeSum.value > props.kaeruMeeting.estimatedTime,
    )
    const agendaConsumptionTimeSum = computed(() => {
      return props.kaeruMeeting.kaeruMeetingAgendas.reduce((sum, agenda) => {
        return sum + agenda.consumptionTime
      }, 0)
    })
    const agendaStartedTimeMin = computed(() => {
      return props.kaeruMeeting.kaeruMeetingAgendas
        .filter((agenda) => {
          return agenda.inProgress
        })
        .reduce((minValue, agenda) => {
          if (minValue === null || minValue > agenda.startedAt) {
            return agenda.startedAt
          }
          return minValue
        }, null)
    })

    const calculateElapsedMinutes = (fromTime, toTime) => {
      const diff = dayjs(toTime).diff(dayjs(fromTime))
      const elapsedTime = dayjs.duration(diff)

      return elapsedTime.hours() * 60 + elapsedTime.minutes()
    }
    const calculateTimeRemainingOnAgenda = () => {
      let returnValue = agendaEstimatedTimeSum.value - agendaConsumptionTimeSum.value
      if (agendaStartedTimeMin.value) {
        returnValue -= calculateElapsedMinutes(agendaStartedTimeMin.value, Date.now())
      }
      timeRemainingOnAgenda.value = Math.max(returnValue, 0)
    }
    const calculateTimeRemainingInTheMeeting = () => {
      if (!props.kaeruMeeting.startedAt) {
        timeRemainingInTheMeeting.value = props.kaeruMeeting.estimatedTime
        return
      }

      const remainingMinites =
        props.kaeruMeeting.estimatedTime -
        calculateElapsedMinutes(props.kaeruMeeting.startedAt, Date.now())

      timeRemainingInTheMeeting.value = `${remainingMinites < 0 ? 0 : remainingMinites}`
    }
    const timeRemainingDisplay = () => {
      calculateTimeRemainingOnAgenda()
      calculateTimeRemainingInTheMeeting()
    }

    onMounted(() => {
      if (!props.showOnly) {
        // NOTE: 残り時間が減っていくに従って表示を更新するためにsetIntervalしている
        timeRemainingTimer.value = setInterval(timeRemainingDisplay, 1000)
      }
    })
    onUnmounted(() => {
      clearInterval(timeRemainingTimer.value)
    })

    return {
      sortingUserForDisplay,
      timeRemainingOnAgenda,
      timeRemainingInTheMeeting,
      isMobile,
      isSignedIn,
      toggleCollapse,
      isOpened,
      agendaEstimatedTimeSum,
      agendaConsumptionTimeSum,
      clientProjectKaeruMeetingPath,
      isOverSchedule,
    }
  },
})
</script>
<style scoped lang="scss"></style>

<template>
  <div class="d-flex align-items-center h-100">
    <a
      v-if="isSignedIn && kaeruMeeting.isBeforeStarting"
      v-show="!showOnly"
      data-confirm="カエル会議を開始してもよろしいですか？"
      data-remote="true"
      rel="nofollow"
      class="btn btn-md btn-primary mx-3 align-self-center kaeru-meeting-in-progress__end-btn"
      data-method="post"
      :href="startUrl"
    >
      会議を開始する
    </a>
    <a
      v-if="isSignedIn && kaeruMeeting.isInProgress"
      v-show="!showOnly"
      data-confirm="カエル会議を終了してもよろしいですか？"
      data-remote="true"
      rel="nofollow"
      class="btn btn-md btn-danger mx-3 mx-md-3 align-self-center kaeru-meeting-in-progress__end-btn"
      data-method="post"
      :href="finishUrl"
    >
      会議を終了する
    </a>
    <span v-show="showOnly" class="mx-3 align-self-center badge rounded-50rem ended-text">
      この会議は終了しました
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isSignedIn = props.currentUserId !== null
    const startUrl = computed(() => {
      return `/api/user/v1/kaeru_meetings/${props.kaeruMeeting.id}/start`
    })
    const finishUrl = computed(() => {
      return `/api/user/v1/kaeru_meetings/${props.kaeruMeeting.id}/finish`
    })

    return {
      startUrl,
      finishUrl,
      isSignedIn,
    }
  },
})
</script>
<style scoped lang="scss">
.ended-text {
  color: rgb(220, 38, 38);
  background-color: rgb(254, 242, 242);
  padding: 8px 15px;
}
</style>

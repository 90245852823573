import { createConsumer } from '@rails/actioncable'

const consumers = {}

function createChannel({ channelParams }) {
  const consumer = createConsumer()
  return consumer.subscriptions.create(...channelParams)
}

export default createChannel

<template>
  <span>
    <button
      id="show-modal"
      class="btn-link text-app-blue-500 font-weight-bold link-unstyled"
      @click.prevent="handleOnShowModal"
    >
      <Icon icon-name="fa-window-maximize" icon-class="far"></Icon>
      画面切替
    </button>
    <transition name="modal">
      <Modal v-if="showModal" @close="showModal = false">
        <template #body>
          <div class="d-flex align-items-center">
            <div class="display-type-icon__container">
              <div class="display-type-icon__separator"></div>
            </div>
            <div class="ml-2 text-app-gray-400 font-size-12rem">画面を分割して使う</div>
          </div>
          <div class="mt-3">
            <label class="d-flex align-items-start">
              <input
                type="radio"
                name="user[kaeru_meeting_display_type]"
                class="mt-2"
                value="type_a"
                :checked="setCheckedOfDisplayType('type_a')"
                @click="handleOnCLickDisplayType('type_a')"
              />
              <div class="ml-2 d-flex flex-column">
                <span class="text-app-gray-700 font-weight-bold font-size-12rem">
                  決定事項 | 議題-意見
                </span>
                <span class="mt-2 text-app-gray-700 font-size-10rem">
                  左側に議題と決定事項が表示されます
                  <br />
                  議題の並び替えができます
                  <br />
                  次回の会議が設定できます
                </span>
              </div>
            </label>
            <label class="d-flex align-items-start">
              <input
                type="radio"
                name="user[kaeru_meeting_display_type]"
                class="mt-2"
                value="type_b"
                :checked="setCheckedOfDisplayType('type_b')"
                @click="handleOnCLickDisplayType('type_b')"
              />
              <div class="ml-2 d-flex flex-column">
                <span class="text-app-gray-700 font-weight-bold font-size-12rem">
                  議事録 | 議題-意見
                </span>
                <span class="mt-2 text-app-gray-700 font-size-10rem">
                  左側に議事録が表示されます
                </span>
              </div>
            </label>
          </div>

          <div class="mt-4 d-flex align-items-center">
            <div class="display-type-icon__container"></div>
            <div class="ml-2 text-app-gray-400 font-size-12rem">画面いっぱいにして使う</div>
          </div>

          <label class="mt-3 d-flex align-items-start">
            <input
              type="radio"
              name="user[kaeru_meeting_display_type]"
              class="mt-2"
              value="type_c"
              :checked="setCheckedOfDisplayType('type_c')"
              @click="handleOnCLickDisplayType('type_c')"
            />
            <div class="ml-2 d-flex flex-column">
              <span class="text-app-gray-700 font-weight-bold font-size-12rem">議題-意見のみ</span>
              <span class="mt-2 text-app-gray-700 font-size-10rem">
                議題の確認や意見出しが全画面でできるようになります
              </span>
            </div>
          </label>
          <span class="mt-4 d-flex justify-content-center">
            <button class="btn btn-sm btn-primary" @click="handleOnClickOK">
              画面を切り替える
            </button>
          </span>
        </template>
      </Modal>
    </transition>
  </span>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Modal from '@/components/modal.vue'
import Icon from '@/components/Icon.vue'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { update } from '@/lib/api/kaeru_meeting_display_type'

export default defineComponent({
  components: {
    Modal,
    Icon,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    displayType: {
      type: String,
      default: 'type_b',
    },
  },
  setup(props, { emit }) {
    const showModal = ref(false)
    const dirtyDisplayType = ref(props.displayType)

    const isSignedIn = props.currentUserId !== null
    const handleOnShowModal = () => {
      showModal.value = true
      dirtyDisplayType.value = props.displayType
    }
    const handleOnClickOK = () => {
      if (isSignedIn) {
        const params = {
          kaeru_meeting_display_type: dirtyDisplayType.value,
        }
        const url = '/api/user/v1/kaeru_meeting_display_type'
        catchAxiosError(async () => {
          await update(url, params)
        })
      }

      emit('onChangeDisplayType', dirtyDisplayType.value)
      showModal.value = false
    }
    const handleOnCLickDisplayType = (displayType) => {
      dirtyDisplayType.value = displayType
    }
    const setCheckedOfDisplayType = (displayType) => {
      return dirtyDisplayType.value === displayType ? 'checked' : ''
    }

    return {
      showModal,
      handleOnShowModal,
      handleOnClickOK,
      handleOnCLickDisplayType,
      dirtyDisplayType,
      setCheckedOfDisplayType,
    }
  },
})
</script>
<style scoped lang="scss">
.display-type-icon__container {
  position: relative;
  width: 60px;
  height: 38px;

  background: #f9fafb;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  border-radius: 4px;
}
.display-type-icon__separator {
  position: absolute;
  width: 38px;
  height: 0;
  left: 10px;
  top: 17px;

  border: 1px solid #d1d5db;
  transform: rotate(90deg);
}
</style>

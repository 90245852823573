<template>
  <form
    v-if="isFormVisible"
    class="d-flex w-100 justify-content-between mx-2"
    @submit.prevent="updateRequest"
  >
    <div class="form-group string required mb-0 flex-grow-1">
      <input
        v-model.trim="form.comment"
        class="form-control string required"
        required="required"
        aria-required="true"
        type="text"
        name="agenda_comment[comment]"
      />
    </div>
    <input
      type="submit"
      value="保存"
      class="ml-2 btn btn-sm btn-outline-primary width-60 align-self-start"
    />
  </form>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, watch } from 'vue'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import { update } from '@/lib/api/kaeru_meetings/agenda_comments'

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    clientId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    guestId: {
      type: String,
      default: null,
    },
    kaeruMeetingAgendaId: {
      type: Number,
      required: true,
    },
    commentData: {
      type: Object,
      required: true,
    },
    isFormVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = reactive({
      comment: props.commentData.comment,
    })
    watch(
      () => props.commentData,
      (newCommentData) => {
        if (!props.isFormVisible) form.comment = newCommentData.comment
      },
    )
    watch(
      () => props.isFormVisible,
      (newValue) => {
        if (newValue) form.comment = props.commentData.comment
      },
    )

    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }

    const commentUpdateUrl = computed(() => {
      return kaeruMeetingClient().commentUrl({
        agendaId: props.kaeruMeetingAgendaId,
        commentId: props.commentData.id,
      })
    })

    const updateRequest = () => {
      const params = {
        comment: form.comment,
      }
      catchAxiosError(async () => {
        await update(commentUpdateUrl.value, params)
        emit('onToggleFormDisplay')
      })
    }

    return {
      form,
      updateRequest,
    }
  },
})
</script>

<template>
  <div class="modal-dialog-mask">
    <div class="modal-dialog-wrapper">
      <div class="modal-dialog-container">
        <div class="modal-dialog-header">
          <slot name="header">
            <span class="w-100 d-flex justify-content-end" @click="$emit('close')">
              <Icon icon-name="fa-times" icon-class="fal cursor-pointer font-size-12rem"></Icon>
            </span>
          </slot>
        </div>

        <div class="modal-dialog-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  components: {
    Icon,
  },
})
</script>
<style scoped lang="scss">
.modal-dialog-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-dialog-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog-container {
  width: 550px;
  margin: 0 auto;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-dialog-enter-active,
.modal-dialog-leave-active {
  transition: opacity 0.5s ease;
}

.modal-dialog-enter-from,
.modal-dialog-leave-to {
  opacity: 0;
}
</style>

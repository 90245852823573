<template>
  <div v-if="isPc">
    <div v-if="isCollapsed" class="h-100">
      <div class="h-100 bg-white">
        <div
          class="px-3 py-2 text-white text-center font-size-11rem heading-closed width-50 position-relative"
        >
          <slot></slot>
          <div class="closed-caption mt-3 mb-1 font-size-14rem">{{ title }}</div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="px-3 py-2 text-white text-center font-size-11rem heading-opened position-relative"
    >
      {{ title }}
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { usePcScreen } from '@/lib/media_query'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isPc = usePcScreen()

    return {
      isPc,
    }
  },
})
</script>
<style scoped lang="scss">
@use 'app/assets/stylesheets/application/resources.sass';

.heading-opened,
.heading-closed {
  background-color: resources.$app-kaeru-meeting-tab-color;
}
.closed-caption {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  // ちょっと調整しないと縦書き文字の場合は中央寄せにならない
  margin-left: -8px;
}
</style>

<template>
  <BDropdown class="cursor-pointer agenda-comment-menu test-agenda-comment-menu">
    <template #button-content>
      <Icon icon-name="fa-ellipsis-v" icon-class="fas text-app-gray-500 px-2"></Icon>
    </template>
    <template #popper>
      <slot></slot>
    </template>
  </BDropdown>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BDropdown from '@/components/BDropdown.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  components: {
    Icon,
    BDropdown,
  },
})
</script>
<style scoped lang="scss">
.agenda-comment-menu {
  :deep(.dropdown-toggle::after) {
    display: none;
  }
}
</style>

<template>
  <div>
    <button
      class="px-2 btn btn-xs rounded-50rem btn-secondary text-default-color font-size-07rem test-generate-task"
      @click="handleOnClick"
    >
      タスク登録
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { post } from '@/lib/api/kaeru_meetings/agenda_comment_task'
import type { KaeruMeetingAgendaComment } from '@/types/models'

export default defineComponent({
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    agendaId: {
      type: Number,
      required: true,
    },
    agendaComment: {
      type: Object as PropType<KaeruMeetingAgendaComment>,
      required: true,
    },
  },
  setup(props) {
    const isSignedIn = computed(() => {
      return props.currentUserId !== null
    })

    const handleOnClick = () => {
      if (isSignedIn) {
        catchAxiosError(async () => {
          await post(props.kaeruMeetingId, props.agendaId, props.agendaComment.id)
        })
      }
    }

    return {
      isSignedIn,
      handleOnClick,
    }
  },
})
</script>

import client from '@/lib/api/client'
import { clientProjectKaeruMeetingProceedingsContentPath } from '@/lib/rails-routes'

const update = async (clientId, projectId, kaeruMeetingId, params) => {
  const url = clientProjectKaeruMeetingProceedingsContentPath({
    clientId,
    projectId,
    kaeruMeetingId,
  })

  const { data } = await client.patch(url, params)
  return data
}

export { update }

import client from '@/lib/api/client'
import { apiUserV1KaeruMeetingImportAgendaPath } from '@/lib/rails-routes'

const post = async (kaeruMeetingId, importFromAgendaId) => {
  const url = apiUserV1KaeruMeetingImportAgendaPath({
    kaeruMeetingId,
    import_from: importFromAgendaId,
  })

  await client.post(url, {})
}

export { post }

<template>
  <div>
    <div
      class="rounded pb-2 mb-4 kaeru-meeting-agenda-container js-kaeru-meeting-agenda-container test-agenda-container"
      :class="{ 'is-started': isStarted }"
      :data-test-id="agendaData.id"
    >
      <div class="agenda-header pt-2 px-2">
        <div
          class="d-flex flex-md-row flex-column-reverse align-items-center justify-content-between position-relative"
        >
          <div class="w-100">
            <div class="hstack height-30">
              <KaeruMeetingAgendaStartEnd
                v-if="!isAgendaEditFormActive"
                ref="startEndButton"
                :current-user-id="currentUserId"
                :current-user-belongs-to-wlb="currentUserBelongsToWlb"
                :kaeru-meeting="kaeruMeeting"
                :kaeru-meeting-id="kaeruMeetingId"
                :kaeru-meeting-token="kaeruMeetingToken"
                :agenda-data="agendaData"
                :show-only="showOnly"
              />
              <div class="ml-auto hstack gap-2">
                <div v-if="!isAgendaEditFormActive" class="text-nowrap">
                  <i class="fal fa-stopwatch text-app-gray-700"></i>
                  <span
                    class="font-size-08rem text-muted ml-1 text-nowrap text-app-gray-700"
                    v-text="displayEstimatedTime"
                  ></span>
                </div>
                <a
                  v-show="!showOnly && guestId === null"
                  href=""
                  title="編集"
                  class="ml-auto"
                  @click.prevent="toggle_agenda_edit_form()"
                >
                  <span
                    class="js-toggle-edit-agenda text-nowrap text-app-blue-500 font-weight-bold font-size-08rem"
                  >
                    編集
                  </span>
                </a>
              </div>
            </div>
            <div
              v-if="!isAgendaEditFormActive"
              class="mt-2 kaeru-meeting-agenda font-size-11rem font-weight-bold test-agenda-title w-100"
              v-html="agendaData.agendaHtml"
            ></div>
            <form
              v-if="isAgendaEditFormActive"
              class="js-kaeru-meeting-patch-agenda d-inline-block w-100"
              :action="agendaUrl"
              accept-charset="UTF-8"
              data-remote="true"
              method="patch"
            >
              <div
                class="w-100 form-group string required flex-grow-1 kaeru_meeting_agenda_agenda mb-0"
              >
                <input
                  id="kaeru_meeting_agenda_agenda"
                  v-model.trim="form.agenda"
                  class="form-control string required"
                  required="required"
                  aria-required="true"
                  type="text"
                  name="kaeru_meeting_agenda[agenda]"
                />
              </div>
              <div class="mt-1 d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <label class="integer optional mb-0" for="kaeru_meeting_agenda_estimated_time">
                    想定時間
                  </label>
                  <div class="form-group integer optional form-group-valid mb-0">
                    <input
                      id="kaeru_meeting_agenda_estimated_time"
                      v-model.trim="form.estimatedTime"
                      class="form-control numeric integer optional form-control width-80 ml-1"
                      type="number"
                      step="1"
                      min="0"
                      name="kaeru_meeting_agenda[estimated_time]"
                    />
                  </div>
                  <div class="ml-1">分</div>
                </div>
                <div class="font-size-08rem text-muted ml-auto">
                  登録者：{{ agendaData.user.name }}
                </div>
              </div>
              <div class="mt-1 d-flex justify-content-end">
                <input
                  type="submit"
                  name="commit"
                  value="保存"
                  class="ml-2 btn btn-sm btn-outline-primary width-60"
                  data-disable
                />
                <a
                  v-show="!showOnly"
                  class="ml-2 btn btn-sm btn-outline-danger width-60 test-agenda-destroy"
                  :data-confirm="`この議題を削除してもよろしいですか？\n（意見も一緒に削除されます）`"
                  data-remote="true"
                  rel="nofollow"
                  data-method="delete"
                  :href="agendaUrl"
                >
                  削除
                </a>
              </div>
            </form>
          </div>
          <transition name="fade">
            <div v-if="isDisplayUpdateInfo" class="position-absolute agenda-comment-update-message">
              <span class="font-size-07rem font-weight-bold text-app-gray-600">
                {{ updatesInfo }}
              </span>
            </div>
          </transition>
        </div>

        <KaeruMeetingCommentPostForm
          v-show="!showOnly"
          :current-user-id="currentUserId"
          :kaeru-meeting-id="kaeruMeetingId"
          :kaeru-meeting-token="kaeruMeetingToken"
          :kaeru-meeting-agenda-id="agendaData.id"
        />
        <div class="mt-3 border-bottom-app-gray-200 agenda-post-form-bottom"></div>
      </div>

      <KaeruMeetingAgendaCommentList
        :id="`collapseAgenda${agendaData.id}`"
        :class="{ collapse: true, show: !isCollapsed }"
        :current-user-id="currentUserId"
        :current-user-belongs-to-wlb="currentUserBelongsToWlb"
        :kaeru-meeting="kaeruMeeting"
        :kaeru-meeting-id="kaeruMeetingId"
        :kaeru-meeting-token="kaeruMeetingToken"
        :guest-id="guestId"
        :kaeru-meeting-agenda-id="agendaData.id"
        :kaeru-meeting-agenda-comments="agendaData.agendaComments"
        :show-only="showOnly"
        :comment-likes="commentLikes"
        :is-started="isStarted"
      />
      <div class="d-flex justify-content-center mt-2">
        <a
          class="btn btn-link js-kaeru-meeting-agenda-collapse"
          href="javascript:void(0)"
          role="button"
          aria-expanded="false"
          @click="toggleCollapse()"
        >
          <span>{{ collapseText }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, PropType, watch, reactive } from 'vue'
import KaeruMeetingAgendaCommentList from '@/components/kaeru_meeting/KaeruMeetingAgendaCommentList.vue'
import KaeruMeetingAgendaStartEnd from '@/components/kaeru_meeting/KaeruMeetingAgendaStartEnd.vue'
import KaeruMeetingCommentPostForm from '@/components/kaeru_meeting/KaeruMeetingCommentPostForm.vue'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import type { CommentLikes } from '@/types/models'

type Form = {
  agenda: string
  estimatedTime: number
}

export default defineComponent({
  components: {
    KaeruMeetingAgendaCommentList,
    KaeruMeetingAgendaStartEnd,
    KaeruMeetingCommentPostForm,
  },
  props: {
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    guestId: {
      type: String,
      default: null,
    },
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    agendaData: {
      type: Object,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
    commentLikes: {
      type: Object as PropType<CommentLikes>,
      default: null,
    },
  },
  setup(props, context) {
    const isAgendaEditFormActive = ref(false)
    const isCollapsed = ref(props.agendaData.agendaComments.length === 0)
    const startEndButton = ref(null)
    const isDisplayUpdateInfo = ref(false)
    const form = reactive<Form>({
      agenda: props.agendaData.agenda,
      estimatedTime: props.agendaData.estimatedTime,
    })

    const toggle_agenda_edit_form = () => {
      isAgendaEditFormActive.value = !isAgendaEditFormActive.value
    }

    watch(
      () => props.agendaData,
      () => displayUpdateInfo(),
    )
    watch(
      () => props.agendaData,
      (newAgendaData) => {
        if (!isAgendaEditFormActive.value) {
          form.agenda = newAgendaData.agenda
          form.estimatedTime = newAgendaData.estimatedTime
        }
      },
    )

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value
    }

    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }

    const isStarted = computed(() => {
      if (!startEndButton.value) return false

      return startEndButton.value.isStarted
    })
    const agendaUrl = computed(() => {
      return kaeruMeetingClient().agendaUrl({ agendaId: props.agendaData.id })
    })
    const displayEstimatedTime = computed(() => `${props.agendaData.estimatedTime}分`)
    const agendaStartUrl = computed(() => {
      return kaeruMeetingClient().agendaStartUrl({ agendaId: props.agendaData.id })
    })
    const collapseText = computed(() => {
      const commentLength = props.agendaData.agendaComments.length
      if (isCollapsed.value) {
        return commentLength === 0 ? `意見を書く` : `${commentLength}件の意見を表示`
      }
      return `意見を隠す`
    })

    const isMovable = computed(() => {
      if (props.currentUserId === null) return false
      if (props.currentUserBelongsToWlb && props.kaeruMeeting.consultantEditable) return true

      return (
        props.kaeruMeeting.facilitatorId === props.currentUserId ||
        props.kaeruMeeting.sortingUserId === props.currentUserId
      )
    })
    const displayUpdateInfo = () => {
      isDisplayUpdateInfo.value = true
      // NOTE: 意見が追加されました とかを読みやすい感じのタイミングで表示させるためにsetTimeoutしている
      setTimeout(() => {
        isDisplayUpdateInfo.value = false
      }, 2000)
    }
    const updatesInfo = computed(() => {
      let message = ''
      switch (props.agendaData.actionName) {
        case 'comment_created':
          message = '意見が追加されました'
          break
        case 'comment_updated':
          message = '意見が更新されました'
          break
        case 'comment_destroyed':
          message = '意見が削除されました'
          break
      }
      return message
    })

    return {
      isAgendaEditFormActive,
      isStarted,
      isCollapsed,
      toggle_agenda_edit_form,
      toggleCollapse,
      agendaUrl,
      displayEstimatedTime,
      agendaStartUrl,
      collapseText,
      isMovable,
      displayUpdateInfo,
      isDisplayUpdateInfo,
      updatesInfo,
      form,
    }
  },
})
</script>

<style scoped lang="scss">
.kaeru-meeting-agenda {
  overflow-wrap: anywhere;
}
.agenda-header {
  position: sticky;
  top: -15px;
  opacity: 0.95;
  background-color: white;
  z-index: 999;
}
.agenda-post-form-bottom {
  margin: 0 -10px;
}
.is-started {
  .agenda-header {
    background-color: #e9f1f9;
  }
}
.agenda-comment-update-message {
  right: 10px;
  bottom: -17px;
}
.fade-enter {
  opacity: 1;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: 1s;
}
.fade-leave-to {
  opacity: 0;
}
</style>

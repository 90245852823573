import $ from 'jquery'
import Sortable from 'sortablejs'
import { onConnect } from '@sonicgarden/onconnect'

$(document).on('ajax:success', 'form.js-kaeru-meeting-post-agenda', ({ currentTarget }, html) => {
  currentTarget.reset()
})

$(document).on('ajax:success', 'form.js-kaeru-meeting-patch-agenda', ({ currentTarget }, html) => {
  currentTarget.reset()
  $(currentTarget)
    .closest('.js-kaeru-meeting-agenda-container')
    .find('.js-toggle-edit-agenda')
    .trigger('click')
})

$(document).on('ajax:success', 'form.js-kaeru-meeting-post-comment', ({ currentTarget }, html) => {
  currentTarget.elements['agenda_comment[comment]'].value = ''
})

$(document).on('click', '.js-kaeru-meeting-movie-heading', ({ currentTarget }) => {
  document.querySelector('.js-kaeru-meeting-right-column').firstElementChild.classList.add('d-none')
  $(document).find('.js-kaeru-meeting-heading').removeClass('is-current')
  $(document).find('.js-kaeru-meeting-movie').addClass('d-none')
  $(document).find('.js-kaeru-meeting-movie-heading').removeClass('is-current')
  $(document)
    .find(`.js-kaeru-meeting-movie[data-id=${$(currentTarget).data('id')}]`)
    .toggleClass('d-none')
  $(document)
    .find(`.js-kaeru-meeting-movie-heading[data-id=${$(currentTarget).data('id')}]`)
    .toggleClass('is-current')
})

$(document).on('change', '.js-kaeru-meeting-movie-only', ({ currentTarget }) => {
  if (currentTarget.checked) {
    $(document).find('.js-kaeru-meeting-heading').addClass('d-none')
  } else {
    $(document).find('.js-kaeru-meeting-heading').removeClass('d-none')
  }
  $(document).find('.js-kaeru-meeting-movie-heading:first').trigger('click')
})

$(document).on('click', '.js-copy-kaeru-meeting-link', ({ currentTarget }) => {
  $(currentTarget).closest('.popover').popover('hide')
})

$(document).on(
  'change',
  '.js-check-copy-kaeru-meeting-record-of-proceedings',
  ({ currentTarget }) => {
    $(document).find('.js-copy-kaeru-meeting-record-of-proceedings').toggleClass('d-none')
  },
)

$(document).on(
  'ajax:success',
  '.js-in-charge-of-kaeru-meeting-modal, .js-next-kaeru-meeting-modal',
  ({ currentTarget }) => {
    $(currentTarget).modal('hide')
  },
)

$(document).on('click', '.js-kaeru-meeting-agenda-collapse', ({ currentTarget }) => {
  $(currentTarget)
    .closest('.js-kaeru-meeting-agenda-container')[0]
    .scrollIntoView({ block: 'nearest' })
})

onConnect('.js-kaeru-meeting-agenda-sortable', (sortable) => {
  Sortable.create(sortable, {
    handle: '.js-sortable-handle',
    animation: 150,
    onUpdate: ({ item, newIndex }) => {
      $.ajax({
        data: {
          position: newIndex,
        },
        type: 'PATCH',
        url: item.dataset.updatePositionUrl,
      })
    },
  })
})

$(document).on('click', '.js-randomly-assign-kaeru-meeting-role', () => {
  const optionsEle = document.querySelector('.js-kaeru-meeting-role-options')
  let selectableValues = Array.from(optionsEle.options)
    .filter((option) => option.value !== '')
    .map((option) => option.value)

  const targets = document.querySelectorAll('.js-kaeru-meeting-role-select')
  Array.from(targets).forEach((target) => {
    if (selectableValues.length === 0) return

    const $target = $(target)
    if ($target.select2('data')[0].id === '') {
      const newValue = selectableValues[Math.floor(Math.random() * selectableValues.length)]
      $target.val(newValue).trigger('change')
    }
    selectableValues = selectableValues.filter((item) => item !== $target.val())
  })
})

$(document).on('click', '.js-clear-kaeru-meeting-role', () => {
  const targets = document.querySelectorAll('.js-kaeru-meeting-role-select')

  Array.from(targets).forEach((target) => {
    const selectableValues = Array.from(target.options).map((option) => option.value)
    if (selectableValues.length !== 0) {
      $(target).val('').trigger('change')
    }
  })
})

import { debounce } from 'lodash-es'

const LOCALSTORAGE_KEY = 'autosave_data'
const ws = new WeakSet()

const load = () => {
  const json = localStorage.getItem(LOCALSTORAGE_KEY)
  return json ? JSON.parse(json) : {}
}

const save = (textarea) => {
  const savedValues = load()
  Object.assign(savedValues, { [textarea.dataset.autosaveKey]: textarea.value })
  const json = JSON.stringify(savedValues)
  localStorage.setItem(LOCALSTORAGE_KEY, json)
}

const clear = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
}

const debouncedSave = debounce(save, 1000)

const setup = () => {
  const savedValues = load()

  Array.from(
    document.querySelectorAll('textarea[data-autosave-key], input[data-autosave-key]'),
  ).forEach((textarea) => {
    if (ws.has(textarea)) return
    ws.add(textarea)

    const form = textarea.closest('form')
    Object.keys(savedValues).forEach((key) => {
      if (textarea.dataset.autosaveKey === key) {
        textarea.value = savedValues[key] // eslint-disable-line no-param-reassign
      }
    })

    textarea.addEventListener('input', ({ currentTarget }) => {
      debouncedSave(currentTarget)
    })
    form.addEventListener('submit', clear)
  })
}

export default setup

<template>
  <div ref="rootElement">
    <KaeruMeetingAgendaContainer
      v-for="kaeruMeetingAgenda in kaeruMeetingAgendas"
      :key="kaeruMeetingAgenda.id"
      :current-user-id="currentUserId"
      :current-user-belongs-to-wlb="currentUserBelongsToWlb"
      :kaeru-meeting="kaeruMeeting"
      :kaeru-meeting-id="kaeruMeeting.id"
      :kaeru-meeting-token="kaeruMeeting.guestUrlToken"
      :guest-id="guestId"
      :show-only="showOnly"
      :agenda-data="kaeruMeetingAgenda"
      :comment-likes="commentLikes"
      :class="`agenda-header-${kaeruMeetingAgenda.id}`"
    />

    <div v-if="!showOnly" class="border p-2 rounded">
      <div class="px-2 font-size-12rem">議題を追加</div>

      <form
        class="js-kaeru-meeting-post-agenda"
        :action="agendaPostUrl"
        accept-charset="UTF-8"
        data-remote="true"
        method="post"
      >
        <div class="d-flex justify-content-between mt-3">
          <div class="form-group string required flex-grow-1 kaeru_meeting_agenda_agenda mb-0">
            <input
              id="kaeru_meeting_agenda_agenda"
              class="form-control string required"
              required="required"
              aria-required="true"
              type="text"
              name="kaeru_meeting_agenda[agenda]"
            />
          </div>

          <input
            type="submit"
            name="commit"
            value="提案"
            class="ml-2 btn btn-sm btn-primary width-90 align-self-start"
            data-disable
          />
        </div>
        <div class="mt-1 d-flex align-items-center">
          <div class="d-flex align-items-center">
            <label class="integer optional mb-0" for="kaeru_meeting_agenda_estimated_time">
              想定時間
            </label>
            <a
              role="button"
              class="js-popover popover-help ml-1"
              data-trigger="focus"
              data-container="body"
              data-toggle="popover"
              data-placement="bottom"
              data-html="true"
              data-content="議題の検討に要する時間"
              href="javascript:void(0)"
              data-original-title=""
              title=""
            >
              <i class="fas fa-question-circle"></i>
            </a>
            <div class="form-group integer optional form-group-valid mb-0">
              <input
                id="kaeru_meeting_agenda_estimated_time"
                class="form-control numeric integer optional form-control width-80 ml-1"
                type="number"
                step="1"
                value="10"
                min="0"
                name="kaeru_meeting_agenda[estimated_time]"
              />
            </div>
            <div class="ml-1">分</div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import KaeruMeetingAgendaContainer from '@/components/kaeru_meeting/KaeruMeetingAgendaContainer.vue'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'

export default defineComponent({
  components: {
    KaeruMeetingAgendaContainer,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
    kaeruMeetingAgendas: {
      type: Array,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    guestId: {
      type: String,
      default: null,
    },
    commentLikes: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const rootElement = ref(null)
    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }
    const scrollIntoAgenda = () => {
      const selectedAgendaId = window.location.hash.slice(1).replace('agenda-', '')
      rootElement.value
        .querySelector(`.agenda-header-${selectedAgendaId}`)
        .scrollIntoView({ behavior: 'smooth' })
    }

    onMounted(() => {
      window.addEventListener('hashchange', () => {
        scrollIntoAgenda()
      })
    })

    const agendaPostUrl = computed(() => {
      return kaeruMeetingClient().agendaPostUrl()
    })

    return {
      agendaPostUrl,
      rootElement,
    }
  },
})
</script>

import $ from 'jquery'

const toggleServicePlanOptions = () => {
  const plan = $('.js-service-plan-select').children('option:selected')[0].value
  const $elements = $('.js-service-plan-ids').find('input[type=radio]')

  $elements.each((_, element) => {
    const $ele = $(element)
    $ele.prop('disabled', $ele.data(plan) !== true)
  })
}

$(document).on('change', '.js-service-plan-select', toggleServicePlanOptions)

$(() => toggleServicePlanOptions)

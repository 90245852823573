<template>
  <div class="text-muted bg-white border rounded test-previous-meeting-container">
    <div class="px-3 py-2">
      <div class="d-flex justify-content-between">
        <div class="text-app-gray-700 font-size-11rem">{{ titleText }}</div>
        <div v-if="isSignedIn && isPreviousMeetingAssigned">
          <a
            :href="
              editClientProjectKaeruMeetingPath({
                clientId: kaeruMeeting.clientId,
                projectId: kaeruMeeting.projectId,
                id: kaeruMeeting.previousKaeruMeetingId,
              })
            "
            class="text-app-blue-500 font-weight-bold font-size-08rem"
            target="_blank"
          >
            編集
            <Icon icon-name="fa-external-link" icon-class="fas"></Icon>
          </a>
        </div>
      </div>
    </div>

    <div class="rounded py-3 border-top">
      <div v-if="!isSignedIn" class="mt-2 d-flex justify-content-center">
        <span class="text-app-gray-800">ゲストの方は、前回のカエル会議が表示されません</span>
      </div>

      <template v-else>
        <span v-if="isPreviousMeetingAssigned">
          <div class="mx-3 d-inline-block">
            <div class="d-flex align-items-center">
              <div class="text-app-gray-900 font-weight-bold font-size-12rem">
                {{ kaeruMeeting.previousKaeruMeeting.title }}
              </div>
            </div>
          </div>
          <button
            class="mt-3 mx-3 text-app-gray-700 font-weight-bold test-summary d-block"
            @click="agendasVisible = !agendasVisible"
          >
            <span v-if="agendasVisible">
              前回の議題を閉じる
              <Icon icon-name="fa-caret-up" icon-class="fas"></Icon>
            </span>
            <span v-else>
              前回の議題を開く
              <Icon icon-name="fa-caret-down" icon-class="fas"></Icon>
            </span>
          </button>
          <span v-if="agendasVisible">
            <div
              v-for="kaeruMeetingAgenda in kaeruMeeting.previousKaeruMeeting.kaeruMeetingAgendas"
              id="collapse-agendas"
              :key="kaeruMeetingAgenda.id"
            >
              <div
                class="rounded py-3 kaeru-meeting-decisions border-top"
                :data-test-id="kaeruMeetingAgenda.id"
              >
                <div class="d-flex align-items-center mb-1 mx-3">
                  <div
                    class="font-size-11rem font-weight-bold text-app-gray-700"
                    v-html="kaeruMeetingAgenda.agendaHtml"
                  ></div>
                  <ImportAgendaButton
                    class="ml-auto"
                    :current-user-id="currentUserId"
                    :kaeru-meeting="kaeruMeeting"
                    :kaeru-meeting-agenda="kaeruMeetingAgenda"
                  ></ImportAgendaButton>
                </div>
                <div
                  v-for="agendaComment in decidedOpinions(kaeruMeetingAgenda)"
                  :key="agendaComment.id"
                >
                  <div class="ml-4 mt-2 agenda-container d-flex align-items-start">
                    <Icon
                      icon-name="fa-check"
                      icon-class="fas p-1 rounded-50rem text-white font-size-06rem decision-icon"
                    ></Icon>
                    <div class="d-flex flex-grow-1 flex-column ml-1">
                      <div class="d-flex">
                        <div class="text-app-gray-700" v-html="agendaComment.comment"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="decidedOpinions(kaeruMeetingAgenda).length === 0"
                  class="ml-4 text-muted"
                >
                  決定事項はありません
                </div>
              </div>
            </div>
          </span>

          <div class="d-flex justify-content-center">
            <form class="mt-3" @submit.prevent="destroyRequest">
              <input
                type="submit"
                value="前回会議から外す"
                class="btn btn-md btn-outline-primary"
                data-confirm="前回会議から外してもよろしいですか？"
              />
            </form>
          </div>
        </span>
        <div v-else>
          <div class="mt-2 d-flex flex-column align-items-center justify-content-center">
            <span class="font-size-12rem font-weight-bold text-app-gray-800">
              前回の会議を選択しましょう
            </span>
            <span class="font-size-08rem text-app-gray-600">
              前回の議題を確認したり、コピーすることができます
            </span>
          </div>
          <div class="mt-3 d-flex flex-wrap justify-content-center">
            <div
              class="btn btn-lg btn-secondary test-select-previous-meeting"
              @click="reloadPreviousMeetingCandidates"
            >
              <span class="text-app-gray-700">終了したカエル会議から選択する</span>
            </div>
          </div>
          <form
            v-if="form.previous_meeting_candidates.length !== 0"
            class="mt-4 mx-2"
            @submit.prevent="postRequest"
          >
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column align-items-center">
                <div class="text-app-gray-700 align-self-start">前回の会議を選択してください</div>
                <select
                  id
                  v-model="form.selectedPreviousMeetingId"
                  name="kaeru_meeting[previous_kaeru_meeting_id]"
                  class="form-control w-100"
                >
                  <option
                    v-for="candidate in form.previous_meeting_candidates"
                    :value="candidate.id"
                  >
                    {{ candidate.meeting_on }} / {{ candidate.title_for_display }}
                  </option>
                </select>

                <input
                  type="submit"
                  value="前回会議として設定する"
                  class="mt-2 btn btn-md btn-primary"
                />
              </div>
            </div>
          </form>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, ref } from 'vue'
import { getPreviousMeetingCandidates } from '@/lib/api/kaeru_meetings/previous_meeting_candidates'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { notifyError } from '@/lib/notify'
import { post, destroy } from '@/lib/api/kaeru_meetings/associate_with_previous_meeting'
import Icon from '@/components/Icon.vue'
import ImportAgendaButton from '@/components/kaeru_meeting/ImportAgendaButton.vue'
import { editClientProjectKaeruMeetingPath } from '@/lib/rails-routes'

export default defineComponent({
  components: {
    Icon,
    ImportAgendaButton,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const agendasVisible = ref(false)
    const form = reactive({
      previous_meeting_candidates: [],
      selectedPreviousMeetingId: null,
    })
    const isSignedIn = computed(() => {
      return props.currentUserId !== null
    })
    const isPreviousMeetingAssigned = computed(() => {
      return props.kaeruMeeting.previousKaeruMeeting.id !== undefined
    })
    const titleText = computed(() => {
      return isPreviousMeetingAssigned ? '前回のカエル会議' : '前回のカエル会議を選択しましょう'
    })

    const reloadPreviousMeetingCandidates = async () => {
      form.previous_meeting_candidates = []
      form.previous_meeting_candidates = await getPreviousMeetingCandidates(props.kaeruMeeting.id)
      if (form.previous_meeting_candidates.length === 0) {
        notifyError('対象の会議がありません')
      }
    }

    const postRequest = () => {
      if (form.selectedPreviousMeetingId === null) return

      catchAxiosError(async () => {
        await post(props.kaeruMeeting.id, form.selectedPreviousMeetingId)
      })
    }

    const destroyRequest = () => {
      catchAxiosError(async () => {
        await destroy(props.kaeruMeeting.id)
        form.previous_meeting_candidates = []
      })
    }

    const decidedOpinions = computed(() => {
      return (kaeruMeetingAgenda) => {
        return kaeruMeetingAgenda.agendaComments.filter((agendaComment) => {
          return agendaComment.decidedAt !== null
        })
      }
    })

    return {
      agendasVisible,
      form,
      isPreviousMeetingAssigned,
      titleText,
      reloadPreviousMeetingCandidates,
      postRequest,
      destroyRequest,
      isSignedIn,
      editClientProjectKaeruMeetingPath,
      decidedOpinions,
    }
  },
})
</script>
<style scoped lang="scss">
.decision-icon {
  width: 22px;
  border: 1px solid;
  background-color: #00b488;
}
</style>

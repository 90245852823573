import $ from 'jquery'
import { onConnect } from '@sonicgarden/onconnect'

onConnect('.js-popover', (el) => {
  $(el).popover()
})

onConnect('.js-popover-no-sanitize', (el) => {
  $(el).popover({ sanitize: false })
})

onConnect('.js-popover-with-hover', (el) => {
  $(el)
    .popover({
      trigger: 'manual',
      html: true,
      animation: false,
      sanitize: false,
    })
    .on('mouseenter', function () {
      $(el).popover('show')
      $('.popover').on('mouseleave', function () {
        $(el).popover('hide')
      })
    })
    .on('mouseleave', function () {
      if ($('.popover:hover').length === 0) {
        $(el).popover('hide')
      }
    })
})

const isMac = (): boolean => navigator.platform.toUpperCase().startsWith('MAC')

const isTouchDevice = (): boolean => 'ontouchstart' in window || !!navigator.maxTouchPoints

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isIE = (): boolean => !!(document as any).documentMode

// NOTE: 実際のブラウザがIEかどうかではなくIE用表示モードになっているかどうかで判定
const isIEMode = (): boolean => {
  const html = document.querySelector('html')
  if (!html) return false

  return html.classList.contains('ie')
}

export { isMac, isTouchDevice, isIE, isIEMode }

import client from '@/lib/api/client'

const post = async (id, params) => {
  const { data } = await client.post(
    `/api/user/v1/kaeru_meetings/${id}/associate_with_next_meeting`,
    {
      kaeru_meeting: params,
    },
  )
  return data
}

const destroy = async (id) => {
  const { data } = await client.delete(
    `/api/user/v1/kaeru_meetings/${id}/associate_with_next_meeting`,
  )
  return data
}

export { post, destroy }

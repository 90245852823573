<template>
  <div>
    <div class="mt-3">
      <draggable
        handle=".kaeru-meeting-agenda__handle"
        animation="300"
        :group="{ name: 'agenda-comments' }"
        :list="dirtyNestedComments"
        :disabled="!isDraggable"
        :move="handleMoveComment"
        class="parent-draggable"
        item-key="id"
        @start="handleStartDrag"
        @end="handleChangeAgendaCommentPosition"
      >
        <template #item="{ element: parentComment }">
          <KaeruMeetingAgendaComment
            :current-user-id="currentUserId"
            :kaeru-meeting-id="kaeruMeetingId"
            :kaeru-meeting-token="kaeruMeetingToken"
            :guest-id="guestId"
            :kaeru-meeting-agenda-id="kaeruMeetingAgendaId"
            :kaeru-meeting-agenda-comments="kaeruMeetingAgendaComments"
            :comment-data="parentComment"
            :show-only="showOnly"
            :is-draggable="isDraggable"
            :comment-like="findCommentLike(parentComment.id)"
            :kaeru-meeting="kaeruMeeting"
            :is-started="isStarted"
          >
            <draggable
              handle=".kaeru-meeting-agenda__handle"
              animation="300"
              :group="{ name: 'agenda-comments' }"
              :list="parentComment.comments"
              :disabled="!isDraggable"
              :move="handleMoveComment"
              item-key="id"
              @start="handleStartDrag"
              @end="handleChangeAgendaChildCommentPosition"
            >
              <template #item="{ element: childComment }">
                <KaeruMeetingAgendaComment
                  :current-user-id="currentUserId"
                  :kaeru-meeting-id="kaeruMeetingId"
                  :kaeru-meeting-token="kaeruMeetingToken"
                  :guest-id="guestId"
                  :kaeru-meeting-agenda-id="kaeruMeetingAgendaId"
                  :kaeru-meeting-agenda-comments="parentComment.comments"
                  :comment-data="childComment"
                  :show-only="showOnly"
                  :is-draggable="isDraggable"
                  :comment-like="findCommentLike(childComment.id)"
                  :kaeru-meeting="kaeruMeeting"
                  :is-started="isStarted"
                />
              </template>
            </draggable>
          </KaeruMeetingAgendaComment>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, PropType } from 'vue'
import draggable from 'vuedraggable'
import { cloneDeep } from 'lodash-es'
import KaeruMeetingAgendaComment from '@/components/kaeru_meeting/KaeruMeetingAgendaComment.vue'
import { update } from '@/lib/api/kaeru_meetings/agenda_comment_position'
import catchAxiosError from '@/lib/api/catch_axios_errors'
import { forGuest, forSignedInUser } from '@/lib/api/kaeru_meetings'
import type { CommentLikes } from '@/types/models'

export default defineComponent({
  components: {
    KaeruMeetingAgendaComment,
    draggable,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeetingId: {
      type: Number,
      required: true,
    },
    kaeruMeetingToken: {
      type: String,
      default: null,
    },
    guestId: {
      type: String,
      default: null,
    },
    kaeruMeetingAgendaId: {
      type: Number,
      required: true,
    },
    kaeruMeetingAgendaComments: {
      type: Array,
      required: true,
    },
    showOnly: {
      type: Boolean,
      required: true,
    },
    kaeruMeeting: {
      type: Object,
      required: true,
    },
    commentLikes: {
      type: Object as PropType<CommentLikes>,
      default: null,
    },
    isStarted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const draggedCommentId = ref(0)
    const relatedCommentId = ref(0)
    // NOTE: vueDraggableにcomputeした値を渡すと正しく動作しないので。https://github.com/SortableJS/Vue.Draggable/issues/52
    const dirtyNestedComments = ref([])
    const beforeMoveNestedComments = ref([])

    const nestedComments = computed(() => {
      const returnValue = []
      let parentComment = {}
      let isUnderSeparator = false

      props.kaeruMeetingAgendaComments.forEach((comment) => {
        const cloneComment = { ...comment } // cloneしとかないとreactiveにならないので
        if (cloneComment.commentType === 'separator') {
          parentComment = cloneComment
          parentComment.comments = []
          returnValue.push(parentComment)
          isUnderSeparator = true
        } else if (isUnderSeparator) {
          parentComment.comments.push(cloneComment)
        } else {
          returnValue.push(cloneComment)
        }
      })
      return returnValue
    })

    watch(
      nestedComments,
      (newValue) => {
        dirtyNestedComments.value = newValue
      },
      { immediate: true },
    )

    const isSignedIn = props.currentUserId !== null
    const kaeruMeetingClient = () => {
      return isSignedIn ? forSignedInUser(props.kaeruMeetingId) : forGuest(props.kaeruMeetingToken)
    }

    const handleMoveComment = (e) => {
      draggedCommentId.value = e.dragged.dataset.id

      relatedCommentId.value = 0
      if (e.related.dataset.id !== undefined) {
        relatedCommentId.value = e.related.dataset.id
      }
      // NOTE: ドラッグする微妙な位置によって、datasetの設定されていない子供の要素が来る事があるので、親要素も見ている
      if (e.related.parentElement.dataset.id !== undefined && relatedCommentId.value === 0) {
        relatedCommentId.value = e.related.parentElement.dataset.id
      }
    }
    const handleStartDrag = () => {
      beforeMoveNestedComments.value = cloneDeep(nestedComments.value)
    }

    const handleChangeAgendaCommentPosition = (e) => {
      callAgendaCommentPosition(e)
    }

    const handleChangeAgendaChildCommentPosition = (e) => {
      callAgendaCommentPosition(e)
    }

    const callAgendaCommentPosition = (e) => {
      if (JSON.stringify(beforeMoveNestedComments.value) === JSON.stringify(nestedComments.value))
        return // 移動していない場合は何もしない

      const params = {
        related: relatedCommentId.value,
        old: e.oldIndex,
        new: e.newIndex,
      }

      const url = kaeruMeetingClient().commentMoveUrl({
        agendaId: props.kaeruMeetingAgendaId,
        commentId: draggedCommentId.value,
      })
      catchAxiosError(async () => {
        await update(url, params)
      })
    }

    const findCommentLike = (commentId) => {
      const ret = props.commentLikes.comments.find((agendaComment) => {
        return agendaComment.agendaCommentId === commentId
      })
      return ret
    }

    const isDraggable = computed(() => {
      if (props.kaeruMeeting.everyoneCanBeSorting) return true
      if (props.currentUserId === null) return false
      if (props.currentUserBelongsToWlb && props.kaeruMeeting.consultantEditable) return true

      return (
        props.kaeruMeeting.facilitatorId === props.currentUserId ||
        props.kaeruMeeting.sortingUserId === props.currentUserId
      )
    })

    return {
      handleStartDrag,
      handleChangeAgendaCommentPosition,
      handleChangeAgendaChildCommentPosition,
      handleMoveComment,
      isDraggable,
      dirtyNestedComments,
      nestedComments,
      findCommentLike,
    }
  },
})
</script>

import client from '@/lib/api/client'

class KaeruMeetingClient {
  private prefixPath: string

  constructor(prefixPath: string) {
    this.prefixPath = prefixPath
  }

  async get() {
    const { data } = await client.get(this.prefixPath)
    return data
  }

  agendaPostUrl(): string {
    return `${this.prefixPath}/agendas`
  }

  agendaUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}`
  }

  commentPostUrl({ agendaId, insertPosition }): string {
    let url = `${this.prefixPath}/agendas/${agendaId}/comments`
    if (insertPosition !== null) {
      url = `${url}?pos=${insertPosition}`
    }
    return url
  }

  agendaStartUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/starts`
  }

  agendaResetUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/resets`
  }

  agendaMoveHigherUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/move_higher`
  }

  agendaMoveUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/position`
  }

  agendaMoveLowerUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/move_lower`
  }

  agendaEndUrl({ agendaId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/ends`
  }

  commentUrl({ agendaId, commentId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/comments/${commentId}`
  }

  commentMoveUrl({ agendaId, commentId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/comments/${commentId}/position`
  }

  likePostUrl({ agendaId, commentId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/comments/${commentId}/likes`
  }

  likeUrl({ agendaId, commentId, likeId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/comments/${commentId}/likes/${likeId}`
  }

  decisionUrl({ agendaId, commentId }): string {
    return `${this.prefixPath}/agendas/${agendaId}/comments/${commentId}/decision`
  }
}

const forSignedInUser = (kaeruMeetingId: number): KaeruMeetingClient => {
  return new KaeruMeetingClient(`/api/user/v1/kaeru_meetings/${kaeruMeetingId}`)
}

const forGuest = (kaeruMeetingToken: string): KaeruMeetingClient => {
  return new KaeruMeetingClient(`/api/guest/v1/kaeru_meetings/${kaeruMeetingToken}`)
}

export { forSignedInUser, forGuest }

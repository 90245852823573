import $ from 'jquery'
import { findIndex } from 'lodash-es'

const isProjectMatch = (term, data) => {
  const normalizedTerm = term.toLowerCase()
  const text = data.text.toLowerCase()
  const keywords = (data.element.dataset.keywords || '').toLowerCase()
  const index = findIndex([text, keywords], (word) => word.indexOf(normalizedTerm) >= 0)

  return index >= 0
}

const searchProjectMatcher = (params, data) => {
  const { term } = params
  if ($.trim(term) === '') {
    return data
  }

  if (data.children === undefined) {
    return null
  }

  const filteredChildren = []
  $.each(data.children, (_idx, child) => {
    if (isProjectMatch(term, child)) {
      filteredChildren.push(child)
    }
  })

  if (filteredChildren.length) {
    const modifiedData = $.extend({}, data, true)
    modifiedData.children = filteredChildren
    return modifiedData
  }

  return null
}

// NOTE: 選択表示に顧客名も表示する
const displayTeamSelectTitle = () => {
  const $ele = $('.js-team-select-container').find('.select2-selection__rendered')
  if ($ele.length === 0) return

  $ele[0].innerText = $('.js-team-select').data('title')
}

const teamSelectOption = (state) => {
  const isUnread = state.element ? state.element.dataset.isUnread : 'false'
  const isCurrent = state.element ? state.element.dataset.isCurrent : 'false'
  return `<div class="team-select-option d-flex align-items-center">${
    state.text
  }<span class="ml-1 ${
    isUnread === 'true' && isCurrent === 'false' ? 'is-unread' : ''
  }"></span></div>`
}

$(() => {
  $('.js-team-select').select2({
    language: 'ja',
    matcher: searchProjectMatcher,
    templateResult: teamSelectOption,
    // NOTE: templateResultでhtmlを使うため必要
    escapeMarkup(markup) {
      return markup
    },
  })
  displayTeamSelectTitle()

  $('.js-team-select').on('change', () => {
    displayTeamSelectTitle()
    document.location.href = $('.js-team-select option:selected').data('url')
  })
})

$(document).on('click', '.js-toggle-sidemenu', (event) => {
  $(document).find('.js-media-tablet-menu').toggleClass('d-none')
})

<template>
  <div>
    <div v-if="isCollapsed" class="h-100">
      <div class="h-100 bg-white">
        <KaeruMeetingBodyContentHeading title="議題と決定事項" :is-collapsed="true">
          <button @click="toggleCollapse()">
            <Icon
              icon-name="fa-bars"
              icon-class="fal text-white position-absolute heading-open font-size-18rem"
            ></Icon>
          </button>
        </KaeruMeetingBodyContentHeading>
      </div>
    </div>
    <div v-else>
      <KaeruMeetingBodyContentHeading title="議題と決定事項">
        <button @click="toggleCollapse()">
          <Icon
            icon-name="fa-times"
            icon-class="fal text-white position-absolute heading-close font-size-18rem"
          ></Icon>
        </button>
      </KaeruMeetingBodyContentHeading>
      <div class="kaeru-meeting-in-progress__body-content">
        <div class="text-muted bg-white border-bottom">
          <KaeruMeetingDecisions
            :current-user-id="currentUserId"
            :current-user-belongs-to-wlb="currentUserBelongsToWlb"
            :kaeru-meeting="kaeruMeeting"
          />
        </div>

        <PreviousKaeruMeeting
          class="mt-3"
          :current-user-id="currentUserId"
          :kaeru-meeting="kaeruMeeting"
        />

        <NextKaeruMeeting
          class="mt-3"
          :current-user-id="currentUserId"
          :kaeru-meeting="kaeruMeeting"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import KaeruMeetingDecisions from '@/components/kaeru_meeting/KaeruMeetingDecisions.vue'
import NextKaeruMeeting from '@/components/kaeru_meeting/NextKaeruMeeting.vue'
import PreviousKaeruMeeting from '@/components/kaeru_meeting/PreviousKaeruMeeting.vue'
import Icon from '@/components/Icon.vue'
import { KaeruMeeting } from '@/types/models'
import KaeruMeetingBodyContentHeading from '@/components/kaeru_meeting/KaeruMeetingBodyContentHeading.vue'

export default defineComponent({
  components: {
    Icon,
    KaeruMeetingDecisions,
    NextKaeruMeeting,
    PreviousKaeruMeeting,
    KaeruMeetingBodyContentHeading,
  },
  props: {
    currentUserId: {
      type: Number,
      default: null,
    },
    currentUserBelongsToWlb: {
      type: Boolean,
      default: false,
    },
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const toggleCollapse = () => {
      emit('toggleCollapseDecisions')
    }

    return {
      toggleCollapse,
    }
  },
})
</script>
<style scoped lang="scss">
.heading-open {
  top: 5px;
  left: 12px;
}
.heading-close {
  top: 7px;
  right: 10px;
}
</style>

<template>
  <div>
    <a
      :data-url="
        editClientProjectKaeruMeetingInChargeOfKaeruMeetingPath({
          clientId: kaeruMeeting.clientId,
          projectId: kaeruMeeting.projectId,
          kaeruMeetingId: kaeruMeeting.id,
        })
      "
      data-replaces=".js-in-charge-of-kaeru-meeting-modal-content"
      data-toggle="modal"
      data-target=".js-in-charge-of-kaeru-meeting-modal"
      rel="nofollow"
      class="js-remote-modal-partial"
      href="javascript:void(0)"
    >
      <span class="font-size-08rem text-app-blue-500 font-weight-bold">
        <i class="fal fa-user-plus"></i>
        役割変更
      </span>
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { editClientProjectKaeruMeetingInChargeOfKaeruMeetingPath } from '@/lib/rails-routes'
import { KaeruMeeting } from '@/types/models'

export default defineComponent({
  props: {
    kaeruMeeting: {
      type: Object as PropType<KaeruMeeting>,
      required: true,
    },
  },
  setup(props) {
    return {
      editClientProjectKaeruMeetingInChargeOfKaeruMeetingPath,
    }
  },
})
</script>

<template>
  <Dropdown :distance="6" theme="dropdown" :triggers="triggers">
    <slot name="button-content"></slot>
    <template #popper>
      <slot name="popper" />
    </template>
  </Dropdown>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Dropdown } from 'floating-vue'

type Trigger = 'hover' | 'focus' | 'touch' | 'click'

export default defineComponent({
  components: {
    Dropdown,
  },
  props: {
    triggers: {
      type: Array as PropType<readonly Trigger[]>,
    },
  },
})
</script>

import $ from 'jquery'

$(document).on('change', '.js-input-file-with-preview', ({ currentTarget }) => {
  const file = currentTarget.files[0]

  if (!file) {
    return
  }

  const previewTarget = document.querySelectorAll(currentTarget.dataset.previewTarget)[0]
  const filereader = new FileReader()
  filereader.onload = () => {
    previewTarget.setAttribute('src', filereader.result)
  }
  filereader.readAsDataURL(file)
})

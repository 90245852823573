<template>
  <div>
    <div class="readmore-container" :class="{ open: isOpened }">
      <div class="readmore-body">
        <slot></slot>
      </div>
    </div>
    <div
      class="readmore-toggle w-100 text-primary cursor-pointer"
      :class="{ close: !isOpened }"
      @click="toggleReadMore"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const isOpened = ref(false)

    const toggleReadMore = () => {
      isOpened.value = !isOpened.value
    }

    return {
      isOpened,
      toggleReadMore,
    }
  },
})
</script>
<style scoped lang="scss">
.readmore-container {
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  &.open {
    max-height: 100rem;
    transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
  }
}

.readmore-body {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 12px 0;
  animation: close 0.1s linear 0.1s forwards;
}

.open {
  :deep(.readmore-body) {
    animation: open 0.1s linear 0s forwards;
  }
}

.readmore-toggle {
  text-align: center;
  font-size: 0.8rem;
  padding-top: 5px;
  display: block;

  &.close {
    margin-top: -40px;
    padding-top: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
    position: relative;
    z-index: 100;
    &::after {
      content: '...すべて見る';
    }
  }
  &::after {
    content: '閉じる';
  }
}

@keyframes open {
  from {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}

@keyframes close {
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
  to {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
}
</style>
